/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Link as RouterLink} from 'react-router'
import {useTransition, animated} from 'react-spring'
import {
  AgImage,
  AgText,
  AgAbsolute,
  useOnClickOutside,
  AgRow,
  AgColumn,
} from '@aghealth/ag-components'
import support from '../assets/icon-support.png'
import supportNavV2 from '../assets/icon-support-nav-v2.png'

import colors from '../theme/colors'

// const filters = {
//   blue: 'invert(47%) sepia(72%) saturate(1718%) hue-rotate(190deg) brightness(102%) contrast(101%)',
//   black:
//     'invert(0%) sepia(0%) saturate(0%) hue-rotate(174deg) brightness(97%) contrast(105%)',
// }

const Link = styled(RouterLink)`
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  text-decoration: none;
  color: ${colors['font-primary']};
  margin-left: 12px;
  margin-top: 15px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.last ? '15px' : '0px')};
  transition: color 0.3ms ease-in-out;
`

const Absolute = animated(AgAbsolute)

const Support = ({onClick, active, isNavV2}) => (
  <AgImage
    active={active}
    src={isNavV2 ? supportNavV2 : support}
    onClick={onClick}
    sx={{
      cursor: 'pointer',
      width: isNavV2 ? 20 : 26,
      height: isNavV2 ? 20 : 26,
      mr: isNavV2 ? 16 : 30,
      // filter: (_, props) => (props.active ? filters.blue : filters.black),
      transition: 'filter 0.3ms ease-in',
      fontWeight: 'bold',
    }}
  />
)

Support.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.number,
}

const MenuText = ({children, ...props}) => (
  <AgText
    cursor="pointer"
    ml="12px"
    mt="15px"
    color="font-primary"
    fontSize="14px"
    lineHeight="16px"
    {...props}
  >
    {children}
  </AgText>
)

MenuText.propTypes = {
  children: PropTypes.node,
}

const matches = {
  '/knowledge-center': 1,
  '/contact-us': 1,
  '/data-import-report': 1,
  '/flow': 1,
  '/personnel': 1,
  '/scenario': 1,
}

const Menu = ({
  transition,
  route,
  search,
  handleToggleShowMenu,
  isSystemView,
}) => (
  <Absolute
    width={300}
    top={70}
    right="10%"
    borderRadius="2px"
    boxShadow="profile"
    bg="white"
    style={transition}
  >
    <AgColumn borderTop="1px solid #CCCCCC">
      <Link
        onClick={handleToggleShowMenu}
        active={route === '/knowledge-center' ? 1 : 0}
        to="/knowledge-center"
      >
        Knowledge Center
      </Link>
      <Link
        onClick={handleToggleShowMenu}
        active={route === '/contact-us' ? 1 : 0}
        to="/contact-us"
        last={1}
      >
        Contact Us
      </Link>
    </AgColumn>
    <AgColumn borderTop="1px solid #CCCCCC">
      <Link
        onClick={handleToggleShowMenu}
        active={route === '/data-import-report' ? 1 : 0}
        to={`/data-import-report/procedures${search}`}
        last={1}
      >
        Data Import Report
      </Link>
    </AgColumn>
    {!isSystemView && (
      <AgColumn borderTop="1px solid #CCCCCC">
        <Link
          onClick={handleToggleShowMenu}
          active={route === '/flow' ? 1 : 0}
          to="/flow"
        >
          Process Maps
        </Link>
        <Link
          onClick={handleToggleShowMenu}
          active={route === '/personnel' ? 1 : 0}
          to="/personnel"
          last={1}
        >
          Personnel
        </Link>
      </AgColumn>
    )}
    <AgColumn borderTop="1px solid #CCCCCC">
      <Link
        onClick={handleToggleShowMenu}
        active={route === '/scenario' ? 1 : 0}
        to="/scenario"
        last={1}
      >
        Scenario Planning
      </Link>
    </AgColumn>
  </Absolute>
)

Menu.propTypes = {
  transition: PropTypes.object,
  route: PropTypes.string,
  search: PropTypes.string,
  handleToggleShowMenu: PropTypes.func,
  isSystemView: PropTypes.bool,
  isNavV2: PropTypes.bool,
}

const SupportMenu = ({pathname, search, isSystemView, isNavV2}) => {
  const route = React.useMemo(() => {
    const name = pathname.split('/')[1]
    return '/'.concat(name)
  }, [pathname])

  const ref = React.useRef()
  const [visible, setVisible] = React.useState(false)
  const transitions = useTransition(visible, {
    from: {opacity: 0},
    enter: {opacity: 1},
    leave: {opacity: 0},
  })
  const handleToggleShowMenu = React.useCallback(() => {
    setVisible(prev => !prev)
  }, [setVisible])
  const handleNotShowMenu = React.useCallback(() => {
    setVisible(false)
  }, [setVisible])
  useOnClickOutside(ref, handleNotShowMenu, document)
  return (
    <AgRow ref={ref} height="100%" alignItems="center">
      <Support
        onClick={handleToggleShowMenu}
        active={matches[route]}
        isNavV2={isNavV2}
      />
      {transitions(
        (style, item) =>
          item && (
            <Menu
              key={item}
              transition={style}
              route={route}
              search={search}
              handleToggleShowMenu={handleToggleShowMenu}
              isSystemView={isSystemView}
            />
          ),
      )}
    </AgRow>
  )
}

SupportMenu.propTypes = {
  pathname: PropTypes.string,
  search: PropTypes.string,

  isSystemView: PropTypes.bool,
  isNavV2: PropTypes.bool,
}

export default SupportMenu
