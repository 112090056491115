import {createFormatTick, createAxisStyles} from '../utils'

const createAxes = config => {
  const bottomAxis = {
    tickFormat: config?.showWeekly
      ? '%-b %-d, %Y'
      : config.chart.bottom_axis.tick_format,
    tickCount: config?.showWeekly ? 'week' : 'month',
    tickTimeStep: config?.showWeekly ? 4 : undefined,
    styles: createAxisStyles(config),
  }
  const leftAxis = {
    tickCount: 5,
    showGridLines: true,
    formatTick: createFormatTick(config, 'left_axis'),
    styles: createAxisStyles(config),
  }
  return {leftAxis, bottomAxis}
}

export default createAxes
