import React from 'react'
import PropTypes from 'prop-types'
import capitalize from 'lodash/capitalize'
import uniqueId from 'lodash/uniqueId'
import {MdClose} from 'react-icons/md'
import {IoIosArrowDown, IoIosArrowUp} from 'react-icons/io'
import {
  AgText,
  AgButton,
  AgSpan,
  AgRow,
  AgColumn,
  AgImage,
  AgTable,
} from '@aghealth/ag-components'
import isFunction from 'lodash/isFunction'
import {toDollarString} from '../../../utils'
import {fetchProductsByPatientId} from '../../../api'
import {format} from '../utils'
import docs from '../../../assets/supplytip-docs.png'
import cases from '../../../assets/supplytip-cases.png'
import doctor from '../../../assets/supplytip-doctor.png'
import calendar from '../../../assets/supplytip-calendar.png'
import patient from '../../../assets/supplytip-patient.png'

const Text = ({label, value, bold}) => (
  <AgText fontSize={12} color="font-primary">
    <AgSpan fontWeight={bold ? 'bold' : 'normal'}>{label}</AgSpan>: {value}
  </AgText>
)

Text.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bold: PropTypes.bool,
}

const ShowLess = props => (
  <AgRow ml="56px" mt="12px" {...props}>
    <AgText
      color="#007DFF"
      fontSize="14px"
      lineHeight="24px"
      fontWeight="bold"
      cursor="pointer"
    >
      SHOW LESS CASE INFO
    </AgText>
    <IoIosArrowUp
      color="#3399FF"
      fontSize="20px"
      cursor="pointer"
      marginLeft="16px"
    />
  </AgRow>
)

const ShowMore = props => (
  <AgRow ml="56px" mt="12px" {...props}>
    <AgText
      color="#007DFF"
      fontSize="14px"
      lineHeight="24px"
      fontWeight="bold"
      cursor="pointer"
    >
      SHOW MORE CASE INFO
    </AgText>
    <IoIosArrowDown
      color="#3399FF"
      fontSize="20px"
      cursor="pointer"
      marginLeft="16px"
    />
  </AgRow>
)

const IconText = ({icon, title, subtitle}) => (
  <AgRow marginTop="12px">
    <AgImage src={icon} height="40px" width="40px" borderRadius="circle" />
    <AgColumn marginLeft="16px">
      <AgText fontSize="12px" lineHeight="16px" color="#041424">
        {title}
      </AgText>
      <AgText fontSize="12px" lineHeight="16px" color="#9CACBC">
        {subtitle}
      </AgText>
    </AgColumn>
  </AgRow>
)

IconText.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
}

const TableStyles = {
  Th: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#9CACBC',
    fontWeight: 'normal',
  },
  Td: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#041424',
    py: '8px',
  },
  TdRow: {
    bg: 'transparent',
    cursor: 'default',
    '&:hover': {
      bg: 'transparent',
    },
  },
}

const NameColumn = {
  heading: 'Name',
  accessor: d => d.product_name,
  align: 'left',
  format: capitalize,
}

const QtyColumn = {
  heading: 'Qty',
  accessor: d => d.quantity_used,
  align: 'right',
}

const TotalSpendColumn = {
  heading: 'Total Spend/Supply',
  accessor: d => d.total_spend,
  format: value => toDollarString(value),
  align: 'right',
}

const columns = [NameColumn, QtyColumn, TotalSpendColumn]

const Content = ({
  hospital_case_number,
  category_name,
  drg_id,
  drg_name,
  raw_cpt,
  procedure_filter,
  raw_procedure_icd10_all,
  surgeon_name,
  date_of_surgery,
  hospital_patient_id,
  supplies,
  close,
  onSeeCostDetails,
}) => {
  const [show, setShow] = React.useState(false)
  const handleOnSeeCostDetails = React.useCallback(() => {
    onSeeCostDetails(hospital_case_number)
  }, [onSeeCostDetails, hospital_case_number])
  const handleShowMore = React.useCallback(() => {
    setShow(true)
  }, [])
  const handleShowLess = React.useCallback(() => {
    setShow(false)
  }, [])
  const total = React.useMemo(
    () =>
      supplies?.length
        ? supplies?.reduce((acc, item) => acc + item.total_spend, 0)
        : 0,
    [supplies],
  )
  return (
    <AgColumn flexDirection="column" bg="#FFFFFF">
      <AgRow
        px="16px"
        height="56px"
        justifyContent="space-between"
        borderBottom="base"
        borderColor="#EFEFEF"
      >
        <AgText
          fontSize="18px"
          lineHeight="25px"
          fontWeight="bold"
          color="#041424"
        >
          {`Case No. ${hospital_case_number}`}
        </AgText>
        <MdClose
          fontSize={28}
          onClick={close}
          color="#041424"
          style={{cursor: 'pointer'}}
        />
      </AgRow>
      <AgColumn px="16px">
        <AgText
          mt="16px"
          fontSize="14px"
          fontWeight="bold"
          lineHeight="20px"
          color="#041424"
        >
          {`Supply Category: ${format(category_name, 'supply_category')}`}
        </AgText>
        <IconText
          icon={docs}
          title={
            drg_id
              ? `${drg_id}: ${drg_name}`
              : `${raw_cpt?.replaceAll(',', ', ')}`
          }
          subtitle={drg_id ? 'DRG Name' : 'CPT Codes'}
        />
        {procedure_filter && (
          <IconText
            icon={cases}
            title={procedure_filter}
            subtitle="Procedure Filter"
          />
        )}
        {!show && <ShowMore onClick={handleShowMore} />}
        {show && (
          <>
            {drg_id && raw_procedure_icd10_all && (
              <IconText
                icon={docs}
                subtitle="ICD10 Codes"
                title={raw_procedure_icd10_all?.replaceAll(',', ', ')}
              />
            )}
            <IconText icon={doctor} subtitle="Physician" title={surgeon_name} />
            <IconText
              icon={calendar}
              subtitle="Date of Surgery"
              title={new Date(`${date_of_surgery}`).toLocaleDateString(
                'en-US',
                {
                  year: '2-digit',
                  month: 'numeric',
                  day: 'numeric',
                  timeZone: 'UTC',
                },
              )}
            />
            <IconText
              icon={patient}
              subtitle="Hospital Patient ID"
              title={hospital_patient_id}
            />
            <ShowLess onClick={handleShowLess} />
          </>
        )}
      </AgColumn>
      <AgColumn px="16px">
        <AgRow py="16px">
          <AgText
            fontSize="14px"
            fontWeight="bold"
            lineHeight="20px"
            color="#041424"
          >
            Supply Costs
          </AgText>
        </AgRow>
        <AgTable
          data={supplies}
          columns={columns}
          rowKeyAccessor={() => `supply_${uniqueId()}`}
          styles={TableStyles}
        />
        <AgRow
          borderTop="base"
          borderColor="#EFEFEF"
          justifyContent="space-between"
          py="16px"
        >
          <AgText
            fontSize="14px"
            lineHeight="20px"
            color="#041424"
            fontWeight="bold"
          >
            Total Cost
          </AgText>
          <AgText
            fontSize="14px"
            lineHeight="20px"
            color="#041424"
            fontWeight="bold"
          >
            {toDollarString(total)}
          </AgText>
        </AgRow>
      </AgColumn>
      {isFunction(onSeeCostDetails) && (
        <AgRow px="16px" justifyContent="flex-start" pb="16px">
          <AgButton
            bg="transparent"
            fontSize="base"
            lineHeight="16px"
            color="link-blue"
            onClick={handleOnSeeCostDetails}
            sx={{
              p: 0,
              textDecoration: 'underline',
            }}
          >
            see cost breakdown details
          </AgButton>
        </AgRow>
      )}
    </AgColumn>
  )
}

Content.propTypes = {
  hospital_case_number: PropTypes.string,
  hospital_patient_id: PropTypes.number,
  surgeon_name: PropTypes.string,
  drg_id: PropTypes.number,
  raw_cpt: PropTypes.string,
  category_name: PropTypes.string,
  date_of_surgery: PropTypes.string,
  supplies: PropTypes.arrayOf(
    PropTypes.shape({
      category_name: PropTypes.string,
      supply_cost: PropTypes.number,
    }),
  ),
  close: PropTypes.func,
  onSeeCostDetails: PropTypes.func,
  drg_name: PropTypes.string,
  procedure_filter: PropTypes.string,
  raw_procedure_icd10_all: PropTypes.string,
}
const useFetchSupplies = (patient_id, stack) => {
  const [loading, setLoading] = React.useState(true)
  const [response, setResponse] = React.useState(null)

  React.useEffect(() => {
    const controller = new AbortController()
    fetchProductsByPatientId({signal: controller.signal, patient_id})
      .then(({data}) => {
        setResponse(data)
        setLoading(false)
      })
      .catch(error => {
         
        console.log(error)
      })
    return () => {
      controller.abort()
    }
  }, [patient_id])

  const massaged = React.useMemo(() => {
    if (!response?.length) return {}
    const category_id = stack.split('_')[1]
    const supplies = response.filter(
      p => p.category_id.toString() === category_id,
    )
    const {category_name} = supplies[0]
    return {
      ...response,
      supplies,
      category_name,
      patient_id,
    }
  }, [response, stack, patient_id])
  return {response: massaged, loading}
}

const SupplyChartTooltip = ({stack, data, ...props}) => {
  const {loading, response} = useFetchSupplies(data.patient_id, stack)
  return (
    <AgColumn
      bg="#FFFFFF"
      border="base"
      borderColor="#EFEFEF"
      borderRadius="8px"
      boxShadow="tooltip"
      width={483}
      maxHeight={492}
      opacity={loading ? 0 : 1}
      sx={{
        variant: 'scrollbars.y',
      }}
    >
      {loading ? null : (
        <Content {...data} stack={stack} {...response} {...props} />
      )}
    </AgColumn>
  )
}

SupplyChartTooltip.propTypes = {
  stack: PropTypes.string,
  data: PropTypes.object,
}

export default SupplyChartTooltip
