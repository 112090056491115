import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex} from '@aghealth/ag-components'

const SparkBar = ({value, height, max, color}) => {
  const barWidth = `${((value / max) * 100).toFixed(2)}%`
  return (
    <AgFlex width={`${max}%`}>
      <AgFlex height={`${height}px`} bg={color} width={barWidth} />
    </AgFlex>
  )
}
SparkBar.propTypes = {
  value: PropTypes.number,
  max: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
}
export default SparkBar
