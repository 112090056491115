import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {Link as RouterLink} from 'react-router'
import {AgRow} from '@aghealth/ag-components'
import {MdMailOutline} from 'react-icons/md'
import colors from '../theme/colors'
import colorsV2 from '../theme-2/colors'

// const filters = {
//   blue: 'invert(47%) sepia(72%) saturate(1718%) hue-rotate(190deg) brightness(102%) contrast(101%)',
//   black:
//     'invert(0%) sepia(0%) saturate(0%) hue-rotate(174deg) brightness(97%) contrast(105%)',
// }

const Link = styled(RouterLink)`
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  text-decoration: none;
  color: ${colors['font-primary']};
  margin-left: 12px;
  margin-top: 15px;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  margin-bottom: ${props => (props.last ? '15px' : '0px')};
  transition: color 0.3ms ease-in-out;
`

const Scorecard = ({active, isNavV2}) => (
  <Link active={active ? 1 : 0} to="/scorecard-management" last={1}>
    <MdMailOutline
      style={{
        cursor: 'pointer',
        marginRight: isNavV2 ? '16px' : '30px',
        fontSize: isNavV2 ? '20px' : '26px',
        // filter: active ? filters.blue : filters.black,
        transition: 'filter 0.3ms ease-in',
        fontWeight: 'bold',
        color: isNavV2 ? colorsV2['new-gray'][500] : colorsV2.base.black,
      }}
    />
  </Link>
)

Scorecard.propTypes = {
  active: PropTypes.number,
  isNavV2: PropTypes.bool,
}

const matches = {
  '/scorecard-management': 1,
}

const ScorecardIcon = ({pathname, isNavV2}) => {
  const route = React.useMemo(() => {
    const name = pathname.split('/')[1]
    return '/'.concat(name)
  }, [pathname])

  return (
    <AgRow height="100%" alignItems="center">
      <Scorecard active={matches[route]} isNavV2={isNavV2} />
    </AgRow>
  )
}

ScorecardIcon.propTypes = {
  pathname: PropTypes.string,
  isNavV2: PropTypes.bool,
}

export default ScorecardIcon
