import isPlainObject from 'lodash/isPlainObject'
import uniqueId from 'lodash/uniqueId'
import {
  SET_CHATBOT_LOADING,
  SET_CHATBOT_MESSAGES,
  SET_CHATBOT_SHOW,
  SET_CHATBOT_ID,
  SET_CHATBOT_STEP_ID,
} from '../types'
import {batch} from 'react-redux'
import configs from '../../configs'
import {fetchChatbot, fetchChatbotById} from '../../api'

const safeObject = obj => {
  if (isPlainObject(obj)) {
    return obj
  }
  return {}
}

const setLoading = payload => ({
  type: SET_CHATBOT_LOADING,
  payload,
})

const setMessages = payload => ({
  type: SET_CHATBOT_MESSAGES,
  payload,
})

const setShow = payload => ({
  type: SET_CHATBOT_SHOW,
  payload,
})

const setChatbotV2StepId = payload => ({
  type: SET_CHATBOT_STEP_ID,
  payload,
})

const setV2Id = payload => ({
  type: SET_CHATBOT_ID,
  payload,
})

const createMessage = (payload, isLast) => ({
  id: uniqueId('message_'),
  date: new Date(),
  isLastMessageInGroup: isLast,
  ...safeObject(payload),
})

const pushMessage = payload => (dispatch, getState) => {
  const {messages} = getState().chatbot
  const result = Array.isArray(payload)
    ? payload?.map((p, i) => createMessage(p, i === payload.length - 1))
    : [createMessage(payload, true)]
  const newMessages = [
    // eslint-disable-next-line no-unsafe-optional-chaining
    ...messages?.map(m => ({...m, isLastMessageInGroup: false})),
    ...result,
  ]
  dispatch(setMessages(newMessages))
}

const clearMessages = () => dispatch => {
  dispatch(setMessages([]))
}

const initialize = () => (dispatch, getState) => {
  const {messages} = getState().chatbot

  if (
    messages &&
    messages.length === 0 &&
    configs.chatbot.initialText.length > 0
  ) {
    dispatch(
      pushMessage({
        type: 'bot',
        text: configs.chatbot.initialText,
        id: 'default',
      }),
    )
  }
}

const resetHistory = () => dispatch => {
  const initialText = configs.chatbot.initialText
  batch(() => {
    dispatch(clearMessages())
    dispatch(setV2Id(null))
    dispatch(
      pushMessage({
        type: 'bot',
        text: initialText,
        id: 'default',
        alts: {
          markdown: initialText,
        },
      }),
    )
  })
}

const RETRY_LIMIT = 80

const pollV2TextMessage = (message_id, thread_id) => (dispatch, getState) => {
  let intervalID,
    retryCount = 0

  const poll = () => {
    if (retryCount >= RETRY_LIMIT) {
      dispatch(
        pushMessage([
          {
            id: uniqueId('error_'),
            type: 'bot',
            text: "I'm sorry, an error occurred.",
          },
        ]),
      )
      dispatch(setLoading(false))
      clearInterval(intervalID)
      return
    }
    fetchChatbotById(message_id)
      .then(response => {
        if (response?.messages?.length > 0) {
          const messages = response?.messages?.map(m => ({
            id: response?.message_id,
            type: 'bot',
            text: m,
          }))
          if (!thread_id) {
            dispatch(setV2Id(response?.thread_id))
          }
          dispatch(pushMessage(messages))
          dispatch(setLoading(false))
          clearInterval(intervalID)
        } else {
          if (response?.msg) {
            const chatbotStepId = response?.msg
            const stepId = getState().chatbot.stepId
            if (stepId !== chatbotStepId) {
              dispatch(setChatbotV2StepId(chatbotStepId))
            }
          }
          retryCount += 1
        }
      })
      .catch(() => {
        dispatch(
          pushMessage([
            {
              id: uniqueId('error_'),
              type: 'bot',
              text: "I'm sorry, an error occurred.",
            },
          ]),
        )
        dispatch(setLoading(false))
        clearInterval(intervalID)
      })
  }

  intervalID = setInterval(poll, 1000)
}

const postV2TextMessage = message => (dispatch, getState) => {
  dispatch(pushMessage(message))
  dispatch(setLoading(true))
  const thread_id = getState().chatbot.id
  fetchChatbot({
    body: {
      message: message?.text,
      thread_id,
    },
  })
    .then(response => {
      dispatch(pollV2TextMessage(response?.id, thread_id))
    })
    .catch(() => {
      dispatch(
        pushMessage([
          {
            id: uniqueId('error_'),
            type: 'bot',
            text: "I'm sorry, an error occurred.",
          },
        ]),
      )
      dispatch(setLoading(false))
    })
}

const postText = messasge => dispatch => {
  return dispatch(postV2TextMessage(messasge))
}

const setMessageFeedback = payload => (dispatch, getState) => {
  const {messages} = getState().chatbot

  const newMessages = messages.map(m => {
    if (Number(m.id) === payload.message_id) {
      return {
        ...m,
        feedbackId: payload?.feedback_id,
        isPositive: payload?.is_positive,
      }
    }
    return m
  })

  dispatch(setMessages(newMessages))
}

const actions = {
  postText,
  initialize,
  resetHistory,
  setShow,
  setMessageFeedback,
}

export default actions
