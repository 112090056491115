import React, {Suspense} from 'react'
import {Routes, Route, Navigate} from 'react-router'
import {useSelector, useDispatch} from 'react-redux'
import {Logout} from '@aghealth/ag-components'
import {WindowRedirect} from '../components'
import {store} from '../store/LazyStore'
import {config} from '../store/actions'
import {withValidateReport} from '../hocs'
import {useAmplitude} from '../hooks'
import {
  selectIsSystemView,
  selectAmplitudeUserId,
  selectOrganization,
  selectIsStaff,
  lazyRetry,
} from '../utils'
import {baseURLV1} from '../api/utils'
import {fetchSession} from '../api'

const Dashboard = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "dashboard-page" */
      /* webpackPrefetch: true */
      '../pages/dashboard'
    ).then(async module => {
      const dashboard = await import('../store/reducers/dashboard').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('dashboard', dashboard)
      return module
    }),
  'dashboard-page',
)

const Scorecard = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "scorecard" */
      /* webpackPrefetch: true */
      '../pages/dashboard/containers/Scorecard'
    ).then(async module => {
      const dashboard = await import('../store/reducers/dashboard').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('dashboard', dashboard)
      return module
    }),
  'scorecard-page',
)

const ExploreData = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "explore-data-page" */
      /* webpackPrefetch: true */
      '../pages/explore-data'
    ).then(async module => {
      const charts = await import('../store/reducers/charts').then(
        chartsModule => chartsModule.default,
      )
      store.injectReducer('charts', charts)
      return module
    }),
  'explore-data-page',
)

const SystemView = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "system-view-page" */
      /* webpackPrefetch: true */
      '../pages/system-view'
    ).then(async module => {
      const system = await import('../store/reducers/system').then(
        systemModule => systemModule.default,
      )
      const dashboard = await import('../store/reducers/dashboard').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('system', system)
      store.injectReducer('dashboard', dashboard)
      return module
    }),
  'system-view-page',
)

const TopInsightsv2 = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "top-insights-v2-page" */
      /* webpackPrefetch: true */
      '../pages/top-insights-v2'
    ).then(async module => {
      const insights = await import('../store/reducers/insights-v2').then(
        insightsModule => insightsModule.default,
      )
      store.injectReducer('insightsV2', insights)
      return module
    }),
  'top-insights-v2-page',
)

const TopInsightsv2ResearchSummary = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "insight-details-page-research-summary" */
      /* webpackPrefetch: true */
      '../pages/top-insights-v2/TopInsightsv2ResearchSummary'
    ).then(async module => {
      const insights = await import('../store/reducers/insights-v2').then(
        insightsModule => insightsModule.default,
      )
      store.injectReducer('insightsV2', insights)
      return module
    }),
  'insight-details-page-research-summary',
)

const TopInsight = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "insight-details-page" */
      /* webpackPrefetch: true */
      '../pages/top-insights-v2/TopInsight'
    ).then(async module => {
      const insights = await import('../store/reducers/insights-v2').then(
        insightsModule => insightsModule.default,
      )
      store.injectReducer('insightsV2', insights)
      return module
    }),
  'insight-details-page',
)

const PACTools = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "pac-tools-page" */
      /* webpackPrefetch: true */
      '../pages/pac-tools'
    ).then(async module => {
      const pac = await import('../store/reducers/pac').then(
        pacModule => pacModule.default,
      )
      store.injectReducer('pac', pac)
      return module
    }),
  'pac-tools-page',
)

const PatientVolumes = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "patient-volumes-page" */
      /* webpackPrefetch: true */
      '../pages/patient-volumes'
    ).then(async module => {
      const pacu = await import('../store/reducers/patient-volumes').then(
        pacuModule => pacuModule.default,
      )
      store.injectReducer('pacu', pacu)
      return module
    }),
  'patient-volumes-page',
)

const DataImportReport = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "data-import-report" */
      /* webpackPrefetch: true */
      '../pages/data-import-report'
    ).then(async module => {
      const report = await import('../store/reducers/report').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('report', report)
      return module
    }),
  'data-import-report-page',
)

const MiscodingTool = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "miscoding-tool" */
      /* webpackPrefetch: true */
      '../pages/miscoding-tool'
    ).then(async module => {
      const miscodings = await import('../store/reducers/miscodings').then(
        miscodingsModule => miscodingsModule.default,
      )
      store.injectReducer('miscodings', miscodings)
      return module
    }),
  'miscoding-tool-page',
)

const Goals = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "goals" */
      /* webpackPrefetch: true */
      '../pages/goals'
    ).then(async module => {
      const goals = await import('../store/reducers/goals').then(
        goalsModule => goalsModule.default,
      )
      store.injectReducer('goals', goals)
      return module
    }),
  'goals-page',
)

const GoalDetails = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "goal-details-page" */
      /* webpackPrefetch: true */
      '../pages/goals/Goal'
    ),
  'goal-details-page',
)

const KnowledgeCenter = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "knowledge-center" */
      /* webpackPrefetch: true */
      '../pages/knowledge-center'
    ).then(async module => module),
  'knowledge-center-page',
)

const ContactUs = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "contact-us" */
      /* webpackPrefetch: true */
      '../pages/contact-us'
    ).then(async module => module),
  'contact-us-page',
)

const Profile = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "profile" */
      /* webpackPrefetch: true */
      '../pages/profile'
    ).then(async module => module),
  'profile-page',
)

const ChangePassword = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "change-password" */
      /* webpackPrefetch: true */
      '../pages/change-password'
    ).then(async module => module),
  'change-password-page',
)

const Iframe = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "iframe-page" */
      /* webpackPrefetch: true */
      '../pages/iframe'
    ).then(async module => {
      const response = await fetchSession()
      if (response.status === 200) return module
      return null
    }),
  'iframe-page',
)

const ScorecardManagement = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "scorecard-management" */
      /* webpackPrefetch: true */
      '../pages/scorecard-management'
    ).then(async module => {
      const dashboard = await import('../store/reducers/dashboard').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('dashboard', dashboard)
      return module
    }),
  'scorecard-management-page',
)

const Hospitals = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "hospitals" */
      /* webpackPrefetch: true */
      '../pages/hospitals'
    ).then(async module => module),
  'hospitals-page',
)

const Alerts = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "alerts" */
      /* webpackPrefetch: true */
      '../pages/alerts'
    ).then(async module => module),
  'alerts-page',
)

const DashboardV2 = lazyRetry(
  () =>
    import(
      /* webpackChunkName: "dashboard-v2" */
      /* webpackPrefetch: true */
      '../pages/dashboard-v2'
    ).then(async module => {
      const reducer = await import('../store/reducers/dashboard-v2').then(
        dashboardModule => dashboardModule.default,
      )
      store.injectReducer('dashboardV2', reducer)

      return module
    }),
  'dashboard-v2-page',
)

const iframeRoutes = [
  {title: 'Process Maps', url: '/flow', iframeSrc: '/flow/inpatient/'},
  {title: 'Personnel', url: '/personnel', iframeSrc: '/personnel/'},
  {title: 'Scenario Planning', url: '/scenario', iframeSrc: '/scenario/'},
  {title: 'Management Dashboard', url: '/manage', iframeSrc: '/manage/'},
]

const routesToRedirect = [{path: '/reset'}, {path: '/admin'}]

const LogoutContainer = () => {
  const dispatch = useDispatch()
  const handleOnMount = React.useCallback(() => {
    dispatch(config.setSkipNPIPrompt(false, {onlyLocalStorage: true}))
    dispatch(config.setSkipDeptPrompt(false, {onlyLocalStorage: true}))
  }, [dispatch])
  return <Logout onMount={handleOnMount} />
}

const LazyRoutes = () => {
  const isSystemView = useSelector(selectIsSystemView)
  const hasExploreData = useSelector(state =>
    state?.config?.feature_flags?.includes(2),
  )
  const hasInsights = useSelector(state =>
    state?.config?.feature_flags?.includes(3),
  )
  const hasMiscodingTool = useSelector(state =>
    state?.config?.feature_flags?.includes(5),
  )
  const hasScorecardManagement = useSelector(state =>
    state?.config?.feature_flags?.includes(6),
  )
  const hasDashboard = useSelector(state =>
    state?.config?.feature_flags?.includes(7),
  )
  const hasPatientVolumes = useSelector(state =>
    state?.config?.feature_flags?.includes(8),
  )
  const hasAlerts = useSelector(state =>
    state?.config?.feature_flags?.includes(12),
  )
  const hasDashboardV2 = useSelector(state =>
    state?.config?.feature_flags?.includes(13),
  )
  const hasGoals = useSelector(state =>
    state?.config?.feature_flags?.includes(14),
  )

  const userId = useSelector(selectAmplitudeUserId)
  
  const email = useSelector(state => 
    state?.config?.user?.email
  )

  const firstName = useSelector(state => 
    state?.config?.user?.first_name
  )

  const lastName = useSelector(state => 
    state?.config?.user?.last_name
  )

  const {id, system} = useSelector(selectOrganization) || {}
  const organizationId = !system ? id : undefined
  const organizationGroupId = system ? id : undefined

  const isStaff = useSelector(selectIsStaff)

  const provider = useSelector(state => state.config.user.provider)

  useAmplitude(userId, organizationId, organizationGroupId, isStaff, email, firstName, lastName)

  return (
    <Suspense fallback={null}>
      <Routes>
        {/*TODO: Remove old link */}
        {hasDashboard && (
          <Route
            path="/explore-summary-data"
            Component={isSystemView ? SystemView : Dashboard}
          />
        )}
        {hasExploreData && (
          <Route path="/explore-procedure-data" Component={ExploreData} />
        )}
        {hasInsights && (
          <Route
            path="/top-insights-v2/:insightId/actionable-insights/research-summary/:researchSummaryId"
            Component={TopInsightsv2ResearchSummary}
          />
        )}
        {hasInsights && (
          <Route path="/top-insights-v2/:id" Component={TopInsight} />
        )}
        {hasInsights && (
          <Route exact path="/top-insights-v2" Component={TopInsightsv2} />
        )}
        <Route path="/pac-tools-v2" Component={PACTools} />
        {hasPatientVolumes && (
          <Route path="/throughput-v2" Component={PatientVolumes} />
        )}
        <Route
          path="/data-import-report/:report"
          Component={withValidateReport(DataImportReport)}
        />
        {hasMiscodingTool && (
          <Route path="/billing" Component={MiscodingTool} />
        )}
        <Route path="/knowledge-center/:videoId" Component={KnowledgeCenter} />
        <Route path="/knowledge-center" Component={KnowledgeCenter} />
        <Route path="/contact-us" Component={ContactUs} />
        <Route path="/profile" Component={Profile} />
        <Route path="/hospitals" Component={Hospitals} />
        {hasAlerts && <Route exact path="/alerts-v2" Component={Alerts} />}
        {hasAlerts && (
          <Route
            exact
            path="/alerts"
            element={<Navigate to="/alerts-v2" replace={true} />}
          />
        )}
        {hasDashboardV2 && <Route exact path="/" Component={DashboardV2} />}
        {!provider && (
          <Route path="/change-password" Component={ChangePassword} />
        )}
        <Route path="/logout" Component={LogoutContainer} />
        {hasScorecardManagement && (
          <Route path="/scorecard-management" Component={ScorecardManagement} />
        )}
        {hasDashboard && (
          <Route exact path="/scorecard" Component={Scorecard} />
        )}
        {hasGoals && <Route path="/goals-v2/:id" Component={GoalDetails} />}
        {hasGoals && <Route path="/goals-v2" Component={Goals} />}
        {iframeRoutes.map(route =>
          isSystemView ? (
            !['Process Maps', 'Personnel'].includes(route.title) && (
              <Route
                path={route.url}
                key={route.url}
                Component={() => (
                  <Iframe
                    title={route.title}
                    url={route.url}
                    iframeSrc={route.iframeSrc}
                  />
                )}
              />
            )
          ) : (
            <Route
              path={route.url}
              key={route.url}
              Component={() => (
                <Iframe
                  title={route.title}
                  url={route.url}
                  iframeSrc={route.iframeSrc}
                />
              )}
            />
          ),
        )}
        {routesToRedirect.map(route => (
          <Route
            path={route.path}
            key={route.path}
            Component={() => {
              const redirectToV1 = `${baseURLV1}${route.path}?next=${window.location.origin}`
              return <WindowRedirect url={redirectToV1} />
            }}
          />
        ))}
        <Route path="*" element={<Navigate to={'/'} replace={true} />} />
      </Routes>
    </Suspense>
  )
}

export default LazyRoutes
