import React from 'react'
import PropTypes from 'prop-types'
import {AgFlex, AgText, AgTable} from '@aghealth/ag-components'
import isEmpty from 'lodash/isEmpty'
import {useSelector} from 'react-redux'
import {useDidMount} from '../../../hooks'
import {fetchProductsByPatientId} from '../../../api'
import {formatColumns} from '../utils/table/utils'
import {toDollarString} from '../../../utils'
import {selectSelectedSupplyCategoriesIds} from '../utils'

const columns = formatColumns({
  columns: [
    {
      align: 'left',
      key: 'product_name',
      title: 'Part Name',
    },
    {
      align: 'left',
      key: 'manufacturer_name',
      title: 'Manufacturer Name',
    },
    {
      align: 'left',
      key: 'manufacturer_number',
      title: 'Manufacturer Number',
    },
    {
      align: 'left',
      key: 'category_name',
      title: 'Supply Category',
    },
    {
      align: 'right',
      key: 'total_spend',
      title: 'Total spend',
      format: '$',
    },
    {
      align: 'right',
      key: 'quantity_used',
      title: 'Quantity',
    },
  ],
})

const BaseStyles = {
  Root: {
    variant: 'tables.case-level-supply-details',
  },
  TdRow: {
    bg: (_, props) => (props.selected ? 'hover-green' : 'white'),
  },
}

const Row = ({text, value, paddingTop = 0}) => (
  <AgFlex paddingTop={paddingTop}>
    <AgText fontSize="14px" color="#333333" fontWeight="xlg">
      {text}
    </AgText>
    <AgText fontSize="14px" color="#333333">
      {value}
    </AgText>
  </AgFlex>
)

Row.propTypes = {
  text: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  paddingTop: PropTypes.number,
}

const CaseLevelSupplyDetails = ({
  patient_id,
  surgeon_name,
  hide_name,
  drg_name,
  drg_id,
  procedure_filter,
  raw_cpt,
  raw_procedure_icd10_all,
  hospital_case_number,
  hospital_patient_id,
  date_of_surgery,
  total_supply_cost,
  showPhysicianNames,
}) => {
  const [patientData, setPatientData] = React.useState(null)
  const selectedSupplyCategoriesIds = useSelector(
    selectSelectedSupplyCategoriesIds,
  )
  useDidMount(signal => {
    fetchProductsByPatientId({signal, patient_id})
      .then(response => {
        setPatientData(
          response.data.filter(d =>
            selectedSupplyCategoriesIds.includes(d.category_id),
          ),
        )
      })
      .catch(error => {
         
        console.log(error)
      })
  })

  window.scrollTo(0, 0)
  return (
    <AgFlex
      padding={40}
      flexDirection="column"
      data-testid="case-level-supply-details"
    >
      {patientData && (
        <>
          <AgText fontSize="24px" fontWeight="xlg" color="#333333">
            Supply Details
          </AgText>
          <AgText
            pt="5px"
            fontSize="16px"
            color="#333333"
            data-testid="case-level-case-number"
          >
            {`Case No. ${hospital_case_number}`}
          </AgText>
          <Row
            paddingTop={20}
            text={drg_id ? 'DRG:\xA0' : 'CPT:\xA0'}
            value={drg_id ? `${drg_id}` : `${raw_cpt}`}
          />
          {raw_procedure_icd10_all && (
            <Row
              text={'ICD10 Codes:\xA0'}
              value={raw_procedure_icd10_all?.replaceAll(',', ', ')}
            />
          )}
          <Row
            text={drg_id ? 'DRG Name:\xA0' : 'Procedure Filter:\xA0'}
            value={drg_name ? `${drg_name}` : `${procedure_filter}`}
          />
          <Row
            text="Physician:&nbsp;"
            value={showPhysicianNames ? surgeon_name : hide_name}
          />
          <Row
            text="Date of Surgery:&nbsp;"
            value={new Date(`${date_of_surgery}`).toLocaleDateString('en-US', {
              year: '2-digit',
              month: 'numeric',
              day: 'numeric',
              timeZone: 'UTC',
            })}
          />
          <Row
            paddingTop={20}
            text="Hospital Patient ID:&nbsp;"
            value={hospital_patient_id}
          />
          <Row
            text="Total Supply Cost:&nbsp;"
            value={toDollarString(total_supply_cost)}
          />
          <AgFlex>
            {!isEmpty(patientData) && (
              <AgFlex
                margin="20px 0px 50px 0px"
                width="calc(100vw - 120px)"
                borderBottomRightRadius="8px"
                borderTopRightRadius="8px"
              >
                <AgTable
                  data={patientData}
                  columns={columns}
                  styles={BaseStyles}
                  icon="triangle"
                />
              </AgFlex>
            )}
          </AgFlex>
        </>
      )}
    </AgFlex>
  )
}

const stringOrNumber = PropTypes.oneOfType([PropTypes.string, PropTypes.number])

CaseLevelSupplyDetails.propTypes = {
  patient_id: stringOrNumber,
  hospital_patient_id: stringOrNumber,
  hospital_case_number: stringOrNumber,
  drg_id: stringOrNumber,
  drg_name: PropTypes.string,
  raw_cpt: stringOrNumber,
  procedure_filter: PropTypes.string,
  date_of_surgery: PropTypes.string,
  surgeon_name: PropTypes.string,
  hide_name: PropTypes.string,
  showPhysicianNames: PropTypes.bool,
  total_supply_cost: PropTypes.number,
  raw_procedure_icd10_all: stringOrNumber,
}

export default CaseLevelSupplyDetails
