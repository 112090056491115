import React from 'react'
import PropTypes from 'prop-types'
import {
  AgBox,
  AgFlex,
  AgText,
  AgButton,
  AgImage,
  X as Close,
} from '@aghealth/ag-components'
import checkmark from '../../assets/circle-check.png'

const FeedbackConfirmation = ({onClose}) => {
  return (
    <AgBox
      sx={{
        width: '606px',
        padding: '24px',
        borderRadius: '12px',
        background: '#FFFFFF',
        boxShadow:
          '0px 8px 8px -4px rgba(16, 24, 40, 0.08), 0px 20px 24px -4px rgba(16, 24, 40, 0.14)',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
      }}
    >
      <AgBox sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Close
          onClick={onClose}
          sx={{
            color: 'new-gray.400',
            cursor: 'pointer',
            fontSize: '24px',
          }}
        />
      </AgBox>
      <AgFlex flexDirection="column" alignItems="center" mb="16px">
        <AgImage src={checkmark} sx={{size: 70}} />
        <AgText
          sx={{
            variant: 'displays.xs-semibold',
            color: 'new-gray.900',
            mt: '24px',
          }}
        >
          Thank you for your feedback
        </AgText>
        <AgText
          sx={{variant: 'texts.sm-regular', color: 'new-gray.600', mt: '8px'}}
        >
          A customer success team member will be in touch with you soon.
        </AgText>
      </AgFlex>
      <AgFlex justifyContent="center" mt="16px">
        <AgButton
          onClick={onClose}
          sx={{
            width: '100%',
            height: '44px',
            p: '10px 10px',
            border: '1px solid #12B76A',
            borderRadius: '8px',
            backgroundColor: '#12B76A',
            color: '#FFFFFF',
            variant: 'texts.md-semibold',
          }}
        >
          Close
        </AgButton>
      </AgFlex>
    </AgBox>
  )
}
FeedbackConfirmation.propTypes = {
  onClose: PropTypes.func,
}

export default FeedbackConfirmation
