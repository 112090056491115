import isArray from 'lodash/isArray'
import {subDays, startOfYear} from 'date-fns'
import {toApiFormat} from '../utils/date'

export const getMetaInfo = action => {
  const meta = action.meta ? action.meta : {}
  const exclude = isArray(meta.exclude) ? meta.exclude : []
  const include = isArray(meta.include) ? meta.include : []
  return {
    exclude,
    include,
  }
}

export const getDateRange = (id, max) => {
  const range = {
    selected_start: null,
    selected_end: max,
  }
  if (id === 1) {
    range.selected_start = toApiFormat(subDays(new Date(max), 30).toISOString())
  }
  if (id === 2) {
    range.selected_start = toApiFormat(subDays(new Date(max), 90).toISOString())
  }
  if (id === 3) {
    range.selected_start = toApiFormat(
      subDays(new Date(max), 365).toISOString(),
    )
  }
  if (id === 4) {
    range.selected_start = toApiFormat(startOfYear(new Date(max)).toISOString())
  }
  return range
}
