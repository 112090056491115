import isEmpty from 'lodash/isEmpty'
import {toJson, getSearchParams, getHeaders, baseURL} from './utils'
import hha from '../assets/icon-homehealth.png'
import irf from '../assets/icon-IRF.png'
import snf from '../assets/icon-snf.png'

const endpoint = `${baseURL}/api/pac-tools`
const getCareLevelAsset = ({value}) => {
  if (value.includes('IRF')) return irf
  if (value.includes('SNF')) return snf
  return hha
}
const getHasSelectedDistanceValue = ({selected_values}, {value}) =>
  selected_values && selected_values[0].value === value
const massagePACFilters = response =>
  response?.map(resp => ({
    ...resp,
    values: resp?.values?.map(r => ({
      ...r,
      type: resp.id,
      id: r.value,
      content: r.label,
      name: r.label,
      selected: resp.id === 'distance' && getHasSelectedDistanceValue(resp, r),
      icon: resp.id === 'care_level' ? getCareLevelAsset(r) : null,
    })),
  }))
const massagePACData = response => ({
  ...response,
  results: response.results.map(data => ({
    ...data,
    los_or_visits: null,
    readmitted: null,
    pac_cost: null,
  })),
})
export const _fetchPACFilters = async options => {
  const url = `${endpoint}/filters`
  return fetch(url, {
    headers: await getHeaders(),
    ...options,
  })
    .then(r => r.json())
    .then(response => {
      if (
        response?.detail ===
        'You do not have permission to perform this action.'
      ) {
        return Promise.reject({status: 403})
      }
      return massagePACFilters(response.data)
    })
}
export const fetchPACZipCodes = toJson(async options => {
  const {params = {}} = options
  const url = `${endpoint}/zipcodes/${params.location}`
  return fetch(url, {
    headers: await getHeaders(),
    ...options,
  })
})
export const fetchPACData = async options => {
  const {params = {}} = options
  const searchParams = getSearchParams(params)
  const search = new URLSearchParams(searchParams)
  const url = `${endpoint}?${search.toString()}`

  return fetch(url, {
    headers: await getHeaders(),
    ...options,
  }).then(r =>
    r.json().then(data =>
      Promise.resolve({
        status: process.env.NODE_ENV === 'test' ? data.status : r.status,
        ok: r.ok,
        statusText: r.statusText,
        // This is temporary until https://aghealth.atlassian.net/browse/DEV-3845 is completed
        data:
          isEmpty(params.bundle) && data.results ? massagePACData(data) : data,
      }),
    ),
  )
}
