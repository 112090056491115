import React from 'react'
import {AgButton, AgFlex, AgImage, AgText} from '@aghealth/ag-components'
import FeedbackModal from './FeedbackModal'
import FeedbackConfirmation from './FeedbackConfirmation'
import Modal from '../Modal'
import plus from '../../assets/annotation-plus.png'

const FeedbackButton = () => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)
  const [isConfirmationVisible, setIsConfirmationVisible] =
    React.useState(false)

  const openFeedbackModal = () => setIsModalVisible(true)
  const closeFeedbackModal = () => setIsModalVisible(false)

  const showConfirmation = () => {
    setIsModalVisible(false)
    setIsConfirmationVisible(true)
  }

  const closeConfirmation = () => setIsConfirmationVisible(false)

  return (
    <AgFlex>
      <AgButton
        sx={{
          position: 'fixed',
          top: '160px',
          right: '0px',
          width: '117px',
          height: '36px',
          padding: '8px 14px',
          borderRadius: '8px 0px',
          backgroundColor: '#ECFDF3',
          color: '#027A48',
          border: '1px solid #ECFDF3',
          textAlign: 'left',
          transform: 'rotate(-90deg)',
          transformOrigin: 'bottom right',
          zIndex: 'xxxxl',
        }}
        onClick={openFeedbackModal}
      >
        <AgFlex alignItems="center">
          <AgText
            sx={{
              variant: 'texts.sm-semibold',
              color: '#027A48',
              mr: '8px',
            }}
          >
            Feedback
          </AgText>
          <AgImage src={plus} sx={{size: 16, transform: 'rotate(90deg)'}} />
        </AgFlex>
      </AgButton>
      {isModalVisible && (
        <Modal
          visible={isModalVisible}
          onClose={closeFeedbackModal}
          justifyContent="center"
        >
          <FeedbackModal
            onClose={closeFeedbackModal}
            onSendFeedback={showConfirmation}
          />
        </Modal>
      )}
      {isConfirmationVisible && (
        <Modal
          visible={isConfirmationVisible}
          onClose={closeConfirmation}
          justifyContent="center"
        >
          <FeedbackConfirmation onClose={closeConfirmation} />
        </Modal>
      )}
    </AgFlex>
  )
}
export default FeedbackButton
