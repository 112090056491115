/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import {
  AgText,
  AgFlex,
  AgDatePicker,
  AgColumn,
  AgBox,
  AgSpan,
} from '@aghealth/ag-components'
import {toDatePickerFormat} from '../../../utils'
import {RadioButtonGroup, ToggleSwitch, InfoTooltip} from '../../../components'

const styles = {
  RadioButton: {
    Input: {
      'data-testid': 'time-period',
    },
  },
}

const DatePicker = ({start, end, min, max, onApply}) => (
  <AgBox width="400px" bg="white" mt="10px">
    <AgDatePicker
      start={toDatePickerFormat(start)}
      end={toDatePickerFormat(end)}
      min={toDatePickerFormat(min)}
      max={toDatePickerFormat(max)}
      onApply={onApply}
      theme={{
        calendar: {
          extend: {
            width: '372px',
          },
          small: {
            daySize: '53.5px',
          },
        },
      }}
    />
  </AgBox>
)

DatePicker.propTypes = {
  start: PropTypes.string,
  end: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  onApply: PropTypes.func,
}

const TimePeriodV2 = ({
  timePeriods,
  onClick,
  onToggleDateSaved,
  onApply,
  dateSaved,
  isCustomRange,
  disabled,
  showPartial,
  // eslint-disable-next-line no-unused-vars
  showWeekly,
  chartType,
  items,
  onToggleShowPartial,
  onIntervalClick,
}) => {
  const handleOnApply = React.useCallback(
    ([start, end]) => {
      onApply({
        value: {id: 5},
        selected_start: start,
        selected_end: end,
      })
    },
    [onApply],
  )
  const handleDateRangeOnClick = React.useCallback(
    selection => {
      onClick({
        value: selection,
        selected_start: '',
        selected_end: '',
      })
    },
    [onClick],
  )
  const handleIntervalOnClick = React.useCallback(
    selection => {
      onIntervalClick(selection)
    },
    [onIntervalClick],
  )
  return (
    <AgColumn>
      <AgSpan>Date Range</AgSpan>
      <RadioButtonGroup
        items={timePeriods.values}
        onClick={handleDateRangeOnClick}
        styles={styles}
      />
      {isCustomRange && (
        <DatePicker
          onApply={handleOnApply}
          start={timePeriods.selected_start}
          end={timePeriods.selected_end}
          min={timePeriods.min}
          max={timePeriods.max}
        />
      )}
      {[2, 3].includes(chartType) && (
        <AgFlex flexDirection="column">
          <AgSpan marginTop="20px">Interval</AgSpan>
          <RadioButtonGroup
            items={items}
            onClick={handleIntervalOnClick}
            styles={styles}
          />
          <AgFlex
            marginTop="20px"
            marginRight="50px"
            marginBottom="10px"
            alignContent="center"
            width="100%"
            alignItems="center"
          >
            <ToggleSwitch
              onClick={onToggleShowPartial}
              on={showPartial}
              styles={styles}
              disabled={disabled}
            />
            <AgText fontSize="14px" textAlign="center">
              Show Partial Week or Month Data
            </AgText>
          </AgFlex>
        </AgFlex>
      )}
      <AgFlex
        marginTop={isCustomRange ? '20px' : '10px'}
        marginBottom="10px"
        alignContent="center"
        width="100%"
        alignItems="center"
      >
        <ToggleSwitch
          onClick={onToggleDateSaved}
          on={dateSaved}
          styles={styles}
          disabled={disabled}
        />
        <AgText fontSize="14px" textAlign="center" ml="5px">
          Save Date Range
          <InfoTooltip text="Turn this on to keep a consistent date range when switching between charts, metrics, and sub-clinical areas." />
        </AgText>
      </AgFlex>
    </AgColumn>
  )
}

TimePeriodV2.propTypes = {
  timePeriods: PropTypes.shape({
    selected_start: PropTypes.string,
    selected_end: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    values: PropTypes.arrayOf(PropTypes.any),
  }),
  onClick: PropTypes.func,
  onApply: PropTypes.func,
  onToggleDateSaved: PropTypes.func,
  dateSaved: PropTypes.bool,
  isCustomRange: PropTypes.bool,
  disabled: PropTypes.bool,
  onIntervalClick: PropTypes.func,
}

export default TimePeriodV2
