import max from 'lodash/max'
import {measureText} from '@aghealth/ag-components'

const BarChartStyles = {
  Bar: {
    Rect: {
      fill: (_, props) =>
        props.selected || props.hovered ? '#3A7971' : '#53ADA2',
    },
  },
}

const VendorChartStyles = {
  Bar: {
    Bar: {
      Rect: {
        'data-testid': 'supply-details-rect',
      },
      Outline: {
        stroke: 'black',
        strokeWidth: 2.5,
        display: (_, props) => (props.selected ? 'block' : 'none'),
      },
    },
  },
}

const createSupplyChartStyles = legendWidth => ({
  Selections: {
    Checkbox: {
      Root: {
        width: '64px',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  },
  Root: {
    width: (_, props) =>
      props.showLegend
        ? `calc(100% - ${legendWidth + props.sliderWidth + 10}px)`
        : `calc(100% - ${props.sliderWidth}px)`,
    height: '100%',
  },
  Bar: {
    Bar: {
      Rect: {
        'data-testid': 'supply-details-rect',
      },
      Outline: {
        stroke: 'black',
        strokeWidth: 2.5,
        display: (_, props) => (props.selected ? 'block' : 'none'),
      },
    },
  },
})

const getLegendWidth = values => {
  if (values?.length > 0) {
    const m = max(values?.map(v => measureText(v.title).width))
    return m + 60 + 10
  }
  return 200
}

const createStyles = config =>
  config.chart.group_mode === 'stacked'
    ? [48].includes(config.id)
      ? createSupplyChartStyles(getLegendWidth(config?.options?.values))
      : [96, 110].includes(config.id)
        ? VendorChartStyles
        : {}
    : BarChartStyles

export default createStyles
