import React from 'react'
import PropTypes from 'prop-types'
import merge from 'lodash/merge'
import uniqueId from 'lodash/uniqueId'
import {AgCheckbox, AgColumn, AgText} from '@aghealth/ag-components'

const defaultStyles = {
  Root: {
    py: '8px',
  },
  Input: {
    'data-testid': 'checkbox',
    size: '16px',
    border: '2px solid',
    bg: (_, props) => (props.checked ? '#129459' : '#F4F4F4'),
    borderRadius: 'sm',
    borderColor: (_, props) =>
      props.checked ? '#129459' : 'rgba(0, 0, 0, 0.54)',
    '& path': {
      stroke: '#F4F4F4',
    },
  },
  Label: {
    cursor: 'pointer',
    ml: '12px',
  },
  Metadata: {
    Description: {
      color: '#9CACBC',
      fontSize: '14px',
      fontWeight: '400',
    },
  },
}

const Checkbox = React.memo(
  ({
    id,
    name,
    selected,
    onChange,
    onEnter,
    type,
    items,
    styles: passedStyles,
    disabled,
    metadata,
  }) => {
    const handleOnChange = React.useCallback(() => {
      onChange({
        id,
        name,
        selected,
        type,
        items,
        metadata,
      })
    }, [onChange, id, name, selected, type, items, metadata])
    const handleOnKeyUp = React.useCallback(
      e => {
        if (onEnter && e.keyCode === 13) {
          onEnter(e)
        }
      },
      [onEnter],
    )
    const styles = React.useMemo(
      () => merge({}, defaultStyles, passedStyles),
      [passedStyles],
    )
    const _id = React.useMemo(() => uniqueId(id), [id])
    const label = React.useMemo(() => {
      if (metadata?.description) {
        return (
          <AgColumn py={1}>
            <AgText
              sx={{
                fontSize: '14px',
                fontWeight: selected ? 'bold' : 'normal',
              }}
            >
              {name}
            </AgText>
            <AgText
              sx={{
                mt: 2,
                color: '#9CACBC',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              {metadata.description}
            </AgText>
          </AgColumn>
        )
      }
      return name
    }, [name, metadata, passedStyles, selected])
    return (
      <AgCheckbox
        id={_id}
        label={label}
        checked={selected}
        onClick={disabled ? undefined : handleOnChange}
        onKeyUp={disabled ? undefined : handleOnKeyUp}
        disabled={disabled}
        styles={styles}
      />
    )
  },
)

Checkbox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.node,
  type: PropTypes.string,
  selected: PropTypes.bool,
  onChange: PropTypes.func,
  styles: PropTypes.object,
  onEnter: PropTypes.func,
  disabled: PropTypes.bool,
  items: PropTypes.array,
  metadata: PropTypes.object,
}

Checkbox.defaultProps = {
  onChange: () => {},
  onEnter: () => {},
  styles: {},
}

export default Checkbox
