import {hasKey} from '../../../../utils'

const hasCaseCount = config => config?.series?.some(s => s.id === 'case_count')

const getEndColumns = config =>
  config?.series?.filter(s => !hasKey(s, 'selected'))

const withHandleOptions = fn => config => {
  if (config?.type === 'options')
    return fn({...config, series: getEndColumns(config)})
  return fn(config)
}

const createByPhysicianPROTable = config => {
  const series = config?.series?.map(s => ({
    format: s?.format,
    key: s?.id,
    title: s?.name,
    align: s?.align,
  }))
  const columns = [
    {
      align: 'left',
      key: 'surgeon_name',
      title: 'Physician',
    },
    {
      key: 'case_count',
      title: 'Case Volume',
    },
    {
      key: 'pre_op_score',
      title: 'Pre-Op PRO',
    },
    {
      key: 'post_op_score',
      title: 'Post-Op PRO',
    },
  ]
  return config?.type === 'options'
    ? {columns, end_columns: series}
    : {columns: [...columns, ...series]}
}

const createByPhysicianTable = config => {
  const series = config?.series?.map(s => ({
    format: s?.format,
    key: s?.id,
    title: s?.name,
    align: s?.align,
  }))
  const columns = hasCaseCount(config)
    ? [
        {
          align: 'left',
          key: 'surgeon_name',
          title: 'Physician',
        },
      ]
    : [
        {
          align: 'left',
          key: 'surgeon_name',
          title: 'Physician',
        },
        {
          key: 'case_count',
          title: 'Case Volume',
        },
      ]
  return config?.type === 'options'
    ? {columns, end_columns: series}
    : {columns: [...columns, ...series]}
}

const createCaseLevelTable = config => {
  const series = config?.series?.map(s => ({
    format: s?.format,
    key: s?.id,
    title: s?.name,
    align: s?.align,
  }))
  const columns = [
    {
      align: 'left',
      key: 'hospital_case_number',
      title: 'Case Information',
    },
  ]
  return config?.type === 'options'
    ? {columns, end_columns: series}
    : {columns: [...columns, ...series]}
}

const createCaseLevelPROTable = config => {
  const series = config?.series?.map(s => ({
    format: s?.format,
    key: s?.id,
    title: s?.name,
    align: s?.align,
  }))
  const columns = [
    {
      align: 'left',
      key: 'hospital_case_number',
      title: 'Case Information',
    },
    {
      key: 'pre_op_score',
      title: 'Pre-Op PRO',
    },
    {
      key: 'post_op_score',
      title: 'Post-Op PRO',
    },
  ]
  return config?.type === 'options'
    ? {columns, end_columns: series}
    : {columns: [...columns, ...series]}
}

const createOverTimeTable = config => {
  const series = config?.series?.map(s => ({
    format: s?.format,
    key: s?.id,
    title: s?.name,
    align: s?.align,
  }))
  const columns = [
    {
      align: 'left',
      format: '%-b %-y',
      parse: '%Y-%m',
      key: 'date_of_surgery',
      title: 'Date Of Surgery',
    },
  ]
  return config?.type === 'options'
    ? {columns, end_columns: series}
    : {columns: [...columns, ...series]}
}

const createOverTimeByPhysicianTable = config => {
  const columns = [
    {
      align: 'left',
      format: '%-b %-y',
      parse: '%Y-%m',
      key: 'date_of_surgery',
      title: 'Date Of Surgery',
    },
  ]
  return {
    columns,
    data_format: config?.series
      ?.slice(0, 1)
      ?.map(s => s.format)
      ?.join(),
    data_key: config?.series?.slice(0, 1)?.map(s => s.id),
    group_key: 'surgeon_name',
  }
}

export const createTable = withHandleOptions(config => {
  if (config?.variant_id === 1)
    return config?.type === 'pro'
      ? createByPhysicianPROTable(config)
      : createByPhysicianTable(config)
  if (config?.variant_id === 2) return createOverTimeTable(config)
  if (config?.variant_id === 3) return createOverTimeByPhysicianTable(config)
  if (config?.variant_id === 4)
    return config?.type === 'pro'
      ? createCaseLevelPROTable(config)
      : createCaseLevelTable(config)
  return config?.table
})
