import React from 'react'
import {useNavigate, useLocation} from 'react-router'

const useHistory = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const memoized = React.useMemo(
    () => ({
      push: (to, state) => navigate(to, {state}),
      location,
    }),
    [navigate, location],
  )
  return memoized
}

export default useHistory
