import {
  createRowKeyAccessor,
  formatColumns as _formatColumns,
  isGroupByOrganization,
} from './utils'
import {createGroupByOrganizationKeys} from '../chart'
import createData from './data'
import createColumns from './columns'
import createSort from './sort'

const createFormatColumns = config => columns =>
  _formatColumns({
    columns,
    id: config.id,
    showSupplyDetailsView: config.showSupplyDetailsView,
    groupByManufacturer: config.groupByManufacturer,
    showWeekly: config.showWeekly,
  })

const withGroupByOrganizationKey = create => config => {
  if (isGroupByOrganization(config)) {
    const table = createGroupByOrganizationKeys(config?.table)

    return create({...config, table})
  }
  return create(config)
}

export const createTable = withGroupByOrganizationKey(_config => {
  const data = createData(_config)
  const config = {..._config, data}

  const formatColumns = createFormatColumns(config)
  const rowKeyAccessor = createRowKeyAccessor(config)
  const columns = createColumns(config, formatColumns)

  const {sortColumn, isAscSort} = createSort(config)
  const title = [49].includes(config.id) ? config.title : null
  const {id} = config
  const variant =
    columns.length > 10
      ? config.id === 48
        ? 'cost-by-case'
        : 'scroll'
      : config.id === 49
        ? 'cost-by-supply'
        : 'base'

  const result = {
    id,
    title,
    data,
    rowKeyAccessor,
    columns,
    sortColumn,
    isAscSort,
    variant,
  }

  return result
})
