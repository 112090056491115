const variants = {
  scrollbars: {
    'y-hide': {
      overflowY: 'scroll',
      '-ms-overflow-style': 'none',
      'scrollbar-width': 'none',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    x: {
      overflowX: 'scroll',
      overflowY: 'hidden',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        height: '7px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: 'rgba(199, 199, 199, 1)',
        boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: 'rgba(135, 135, 135, 1)',
      },
    },
    y: {
      overflowY: 'auto',
      overflowX: 'hidden',
      'scrollbar-color': 'rgba(0, 0, 0, .5) transparent',
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'track',
        width: '7px',
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: '4px',
        backgroundColor: '#C4C4C4',
        boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
        backgroundClip: 'padding-box',
      },
    },
  },
  tables: {
    round: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
      tableLayout: 'fixed',
      th: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#041424',
        p: 3,
        verticalAlign: 'text-top',
        background: 'white',
        borderTop: 'base',
        borderColor: 'box-border-gray',
        fontWeight: 400,
      },
      'th:first-child': {
        borderLeft: 'base',
        borderColor: 'box-border-gray',
        borderTopLeftRadius: 'base',
      },
      'th:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
        borderTopRightRadius: 'base',
      },
      td: {
        verticalAlign: 'text-top',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#041424',
        px: 3,
        py: '22px',
      },
      'tbody tr:last-child td:first-child': {
        borderBottomLeftRadius: 'base',
        borderLeft: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td': {
        borderBottom: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td:last-child': {
        borderBottomRightRadius: 'base',
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
      'td:first-child': {
        borderLeft: 'base',
        fontWeight: 'normal',
        borderColor: 'box-border-gray',
      },
      'td:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
    },
    'pacu-round': {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
      tableLayout: 'fixed',
      th: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#041424',
        p: 3,
        verticalAlign: 'text-top',
        background: 'white',

        fontWeight: 400,
      },
      'th:first-child': {
        borderLeft: 'base',
        borderColor: 'box-border-gray',
      },
      'th:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
      td: {
        verticalAlign: 'text-top',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#041424',
        px: 3,
        py: '22px',
      },
      'tbody tr:last-child td:first-child': {
        borderBottomLeftRadius: 'base',
        borderLeft: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td': {
        borderBottom: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td:last-child': {
        borderBottomRightRadius: 'base',
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
      'td:first-child': {
        borderLeft: 'base',
        fontWeight: 'normal',
        borderColor: 'box-border-gray',
      },
      'td:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
    },
    'insights-explore': {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
      tableLayout: 'fixed',
      th: {
        verticalAlign: 'text-top',
        fontSize: '12px',
        lineHeight: '14px',
        color: '#333333',
        px: 3,
      },
      td: {
        fontSize: '14px',
        lineHeight: '16px',
        color: '#333333',
        p: 3,
      },
      'tbody tr:last-child td:first-child': {
        borderBottomLeftRadius: 'base',
      },
      'tbody tr:last-child td:last-child': {
        borderBottomRightRadius: 'base',
      },
    },
    scroll: {
      borderCollapse: 'separate',
      borderSpacing: 0,
      width: '100%',
      tableLayout: 'fixed',
      td: {
        boxSizing: 'border-box',
        width: '120px',
        fontSize: '16px',
        lineHeight: '20px',
        px: 3,
        py: '22px',
      },
      th: {
        boxSizing: 'border-box',
        width: '120px',
        height: '50px',
        p: 3,
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'normal',
        bg: 'white',
        borderTop: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td:first-child': {
        borderBottomLeftRadius: 'base',
        borderBottom: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td:last-child': {
        borderBottomRightRadius: 'base',
        borderColor: 'box-border-gray',
      },
      'td:first-child': {
        boxSizing: 'border-box',
        position: 'sticky',
        left: 0,
        borderRight: '14px',
        borderLeft: 'base',
        borderColor: 'box-border-gray',
        bg: 'white',
        width: '120px',
      },
      'td:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
      'th:first-child': {
        position: 'sticky',
        left: 0,
        height: '82px',
        width: '120px',
        bg: 'white',
        borderRight: '14px',
        borderLeft: 'base',
        borderTopLeftRadius: 'base',
        borderColor: 'box-border-gray',
        px: 3,
      },
      'th:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
        borderTopRightRadius: 'base',
      },
    },
    'cost-by-case': {
      borderCollapse: 'separate',
      borderSpacing: 0,
      width: '100%',
      tableLayout: 'fixed',
      td: {
        boxSizing: 'border-box',
        width: '150px',
        fontSize: '16px',
        lineHeight: '20px',
        px: 3,
        py: '22px',
      },
      th: {
        boxSizing: 'border-box',
        width: '150px',
        height: '50px',
        p: 3,
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'normal',
        bg: 'white',
        borderTop: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td:first-child': {
        borderBottomLeftRadius: 'base',
        borderBottom: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td:last-child': {
        borderBottomRightRadius: 'base',
        borderColor: 'box-border-gray',
      },
      'td:first-child': {
        boxSizing: 'border-box',
        position: 'sticky',
        left: 0,
        borderRight: '14px',
        borderLeft: 'base',
        borderColor: 'box-border-gray',
        bg: 'white',
        width: '175px',
      },
      'td:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
      'th:first-child': {
        position: 'sticky',
        left: 0,

        width: '175px',
        bg: 'white',
        borderRight: '14px',
        borderLeft: 'base',
        borderTopLeftRadius: 'base',
        borderColor: 'box-border-gray',
        px: 3,
      },
      'th:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
        borderTopRightRadius: 'base',
      },
    },
    // 'cost-by-case': {
    //   borderCollapse: 'separate',
    //   borderSpacing: 0,

    //   width: '100%',
    //   tableLayout: 'fixed',
    //   td: {
    //     width: '175px',
    //     height: '150px',
    //     boxSizing: 'border-box',
    //     fontSize: '16px',
    //     lineHeight: '20px',
    //     verticalAlign: 'middle',
    //     px: 3,
    //     py: '22px',
    //   },
    //   th: {
    //     width: '150px',
    //     height: '50px',
    //     borderTop: 'base',
    //     borderColor: 'box-border-gray',
    //     padding: 0,
    //     boxSizing: 'border-box',
    //     fontSize: '14px',
    //     lineHeight: '20px',
    //     px: 3,
    //     py: '22px',
    //     fontWeight: 'normal',
    //     verticalAlign: 'text-top',
    //     background: 'white',
    //   },
    //   'tbody tr:last-child td:first-child': {
    //     borderBottomLeftRadius: 'base',
    //   },
    //   'tbody tr:last-child td:last-child': {
    //     borderBottomRightRadius: 'base',
    //   },
    //   'td:first-child': {
    //     position: 'sticky',

    //     left: 0,
    //     borderLeft: 'base',
    //     borderRight: '14px',
    //     borderColor: 'box-border-gray',
    //     bg: 'white',
    //     fontSize: 'base',
    //     display: 'flex',
    //     alignItems: 'center',
    //     fontWeight: 'normal',
    //     justifyContent: 'flex-start',
    //     wordBreak: 'break-word',
    //   },
    //   'td:last-child': {
    //     borderRight: 'base',
    //     borderColor: 'box-border-gray',
    //   },
    //   'th:first-child': {
    //     position: 'sticky',

    //     left: 0,
    //     display: 'flex',
    //     alignItems: 'flex-start',
    //     justifyContent: 'flex-start',
    //     borderLeft: 'base',
    //     borderRight: '14px',
    //     borderTopLeftRadius: 'base',
    //     width: '175px',

    //     borderColor: 'box-border-gray',
    //     bg: 'white',

    //     fontSize: '14px',
    //     lineHeight: '20px',
    //     px: 3,
    //   },
    //   'th:last-child': {
    //     borderRight: 'base',
    //     borderColor: 'box-border-gray',
    //     borderTopRightRadius: 'base',
    //   },
    // },
    'pac-tools': {
      width: '100%',
      tableLayout: 'fixed',
      th: {
        fontSize: '14px',
        color: '#6A6C78',
        fontWeight: 'lg',
        py: 2,
        px: 3,
        borderBottom: '1px solid #E7EDF0',
        paddingBottom: '20px',
        verticalAlign: 'top',
      },
      td: {
        cursor: 'default',
        py: 10,
        px: 3,
        height: 48,
        fontSize: '14px',
        color: 'font-primary',
        borderBottom: '1px solid #E7EDF0',
        verticalAlign: 'top',
      },
    },
    'pac-tools-printer': {
      width: '100%',
      tableLayout: 'fixed',
      borderCollapse: 'collapse',
      tbody: {
        display: 'block',
        width: '100%',
        overflowY: 'scroll',
        height: '250px',
      },
      'thead tr': {
        display: 'block',
      },
      th: {
        fontSize: '14px',
        color: '#6A6C78',
        fontWeight: 'lg',
        py: 2,
        px: 3,
        borderBottom: '1px solid #E7EDF0',
        paddingTop: '15px',
        verticalAlign: 'top',
      },
      td: {
        cursor: 'default',
        py: 10,
        px: 3,
        height: 48,
        fontSize: '14px',
        color: 'font-primary',
        borderBottom: '1px solid #E7EDF0',
        verticalAlign: 'top',
      },
    },
    'system-view': {
      width: '100%',
      tableLayout: 'fixed',
      th: {
        fontSize: '12px',
        fontWeight: 'normal',
        px: '20px',
        py: '20px',
      },
      td: {
        px: '20px',
        py: '10px',
        height: 48,
        fontSize: '14px',
        verticalAlign: 'top',
      },
    },
    dashboard: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    'system-view-summary': {
      width: '100%',
      tableLayout: 'fixed',
      th: {
        textAlign: 'left',
        fontSize: '12px',
        fontWeight: 'normal',
        px: '20px',
        py: '10px',
      },
      td: {
        height: '48px',
        py: '10px',
        fontSize: '14px',
        fontWeight: 'normal',
        px: '20px',
      },
    },
    'cost-by-supply': {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
      tableLayout: 'fixed',
      td: {
        verticalAlign: 'middle',
        px: 3,
        py: '22px',
        fontSize: '16px',
        lineHeight: '20px',
      },
      th: {
        verticalAlign: 'text-top',
        fontSize: '14px',
        lineHeight: '20px',
        fontWeight: 'normal',
        bg: 'white',
        p: 3,
      },
      'tbody td:first-child': {
        fontWeight: 'bold',
      },
      'th:first-child': {
        borderLeft: 'base',
        borderColor: 'box-border-gray',
      },
      'th:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td:first-child': {
        borderBottomLeftRadius: 'base',
      },
      'tbody tr:last-child td:last-child': {
        borderBottomRightRadius: 'base',
      },
      'td:first-child': {
        borderLeft: 'base',
        borderColor: 'box-border-gray',
      },
      'td:nth-child(3)': {
        wordBreak: 'break-word',
      },
      'td:nth-child(5)': {
        width: '15%',
      },
      'td:nth-child(5) h1': {
        paddingLeft: '10px',
      },
      'td:nth-child(6)': {
        width: '15%',
      },
      'td:nth-child(6) h1': {
        paddingLeft: '10px',
      },
      'td:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td': {
        borderBottom: 'base',
        borderColor: 'box-border-gray',
      },
    },
    'case-level-supply-details': {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
      tableLayout: 'fixed',
      th: {
        fontSize: '14px',
        color: 'font-primary',
        p: 3,
        fontWeight: 'bold',
        verticalAlign: 'text-top',
      },
      td: {
        borderBottom: 'base',
        borderColor: 'box-border-gray',
        verticalAlign: 'text-top',
        fontSize: '14px',
        color: 'font-primary',
        p: 3,
      },
      'tbody tr:first-child td:first-child': {
        borderLeft: 'base',
        borderColor: 'box-border-gray',
        borderTopLeftRadius: 'base',
      },
      'tbody tr:first-child td:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
        borderTopRightRadius: 'base',
      },
      'tbody tr:last-child td:first-child': {
        borderBottomLeftRadius: 'base',
        borderLeft: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:last-child td:last-child': {
        borderBottomRightRadius: 'base',
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
      'td:first-child': {
        borderLeft: 'base',
        borderColor: 'box-border-gray',
        wordBreak: 'break-word',
      },
      'td:last-child': {
        borderRight: 'base',
        borderColor: 'box-border-gray',
      },
      'tbody tr:first-child td': {
        borderTop: 'base',
        borderColor: 'box-border-gray',
      },
    },
  },
}

export default variants
