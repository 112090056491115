import {baseURL, getHeaders} from './utils'

const userFeedback = `${baseURL}/api/user_feedback`

export const createUserFeedback = async options => {
  const {method = 'POST', body = {}} = options
  return fetch(userFeedback, {
    method,
    headers: await getHeaders(),
    body: JSON.stringify(body),
  }).then(r => {
    if (/2[0-9][0-9]/g.test(r.status)) {
      return r.json()
    } else {
      return Promise.reject()
    }
  })
}
