import {getHeaders, baseURL} from './utils'

const endpoint = `${baseURL}/api/goals`

const replaceStandardizeConstruct = async response => {
  const {data, ...rest} = await response
  const updated = data?.map(d => ({
    ...d,
    insight_type:
      d?.insight_type === 'Standardize Construct'
        ? 'Standardize'
        : d?.insight_type,
  }))
  return Promise.resolve({data: updated, ...rest})
}

export const fetchGoals = async (options = {}) => {
  const {
    method,
    body,
    search,
    onSuccess = () => {},
    onFail = () => {},
  } = options
  let url = endpoint
  if (search) {
    url = url?.concat(search)
  }
  return fetch(url, {
    ...options,
    method,
    headers: await getHeaders(),
    body: body ? JSON.stringify(body) : undefined,
  }).then(r =>
    method === 'GET'
      ? replaceStandardizeConstruct(r.json())
      : /2[0-9][0-9]/g.test(r.status)
        ? onSuccess(r)
        : onFail(r),
  )
}

export const fetchGoalDetails = async (id, options = {}) => {
  const url = `${endpoint}/${id}`

  return fetch(url, {
    ...options,
    method: 'GET',
    headers: await getHeaders(),
  }).then(r => replaceStandardizeConstruct(r.json()))
}

export const deleteGoal = async (id, options = {}) => {
  const url = `${endpoint}/${id}`

  return fetch(url, {
    ...options,
    method: 'DELETE',
    headers: await getHeaders(),
  }).then(r => replaceStandardizeConstruct(r.json()))
}

export const updateGoal = async (id, options = {}) => {
  const url = `${endpoint}/${id}`

  return fetch(url, {
    ...options,
    body: options?.body ? JSON.stringify(options?.body) : undefined,
    method: 'PUT',
    headers: await getHeaders(),
  }).then(r => replaceStandardizeConstruct(r.json()))
}
