import React from 'react'
import PropTypes from 'prop-types'
import {
  AgBox,
  AgFlex,
  AgText,
  AgButton,
  AgTextarea,
  ThumbsUp,
  ThumbsDown,
  X as Close,
} from '@aghealth/ag-components'
import RadioButton from '../RadioButton'
import DropdownButton from '../DropdownButton'
import {createUserFeedback} from '../../api'

const feedbackSelections = {
  positive: [
    'I found what I was looking for',
    'The information in CareMeasurement was helpful',
    'I learned what my next steps are for my organization',
    'Other (details in the description below)',
  ],
  negative: [
    'The data about my organization is incorrect',
    'I could not find what I was looking for',
    'There is a software bug / the website is broken',
    'Other (details in the description below)',
  ],
}

const FeedbackModal = ({onClose, onSendFeedback}) => {
  const [experience, setExperience] = React.useState(null)
  const [reason, setReason] = React.useState('')
  const [details, setDetails] = React.useState('')
  const dropdownRef = React.useRef(null)

  const getFeedbackDetails = e => {
    e.preventDefault()
    setDetails(e.target.value)
  }
  const handleExperienceChange = (newExperience) => {
    if (experience !== newExperience) {
      setExperience(newExperience);
      setReason('');
    } else {
      setExperience(newExperience);
    }
  };
  const handleOnSendFeedback = () => {
    if (!experience) return null
    const feedbackData = {
      rating: experience === 'positive' ? 1 : 0,
      reason,
      details,
      screen_url: window.location.href,
    }
    createUserFeedback({body: feedbackData, method: 'POST'})
      .then(() => {
        setExperience(null)
        setReason('')
        setDetails('')
        onSendFeedback()
      })
      .catch(error => console.log(error))
  }
  return (
    <AgBox
      sx={{
        width: '606px',
        height: '694px',
        borderRadius: '12px',
        boxShadow:
          '0px 8px 8px -4px rgba(16, 24, 40, 0.08), 0px 20px 24px -4px rgba(16, 24, 40, 0.14)',
        border: '1px solid #F9FAFB',
        background: '#FFFFFF',
        padding: '24px',
      }}
    >
      <AgFlex justifyContent="space-between" mb="4px">
        <AgText
          sx={{
            variant: 'texts.lg-semibold',
            color: 'new-gray.900',
          }}
        >
          Help us improve
        </AgText>
        <Close
          onClick={onClose}
          sx={{
            color: 'new-gray.400',
            cursor: 'pointer',
          }}
        />
      </AgFlex>
      <AgText
        sx={{
          variant: 'texts.sm-regular',
          color: 'new-gray.600',
          mb: '24px',
        }}
      >
        Your feedback will help us improve. Tell us about your experience with
        CareMeasurement.
      </AgText>

      <AgText
        sx={{
          variant: 'texts.sm-semibold',
          color: 'new-gray.700',
          mb: '8px',
        }}
      >
        My experience today has been:
      </AgText>
      <AgFlex flexDirection="column" mb="16px">
        <AgBox
          sx={{
            cursor: 'pointer',
            transition: 'background 0.2s, border 0.2s',
            borderRadius: '12px',
            padding: '16px',
            mb: '12px',
            background: experience === 'positive' ? '#ECFDF3' : '#FFFFFF',
            border:
              experience === 'positive'
                ? '1px solid #12B76A'
                : '1px solid #EAECF0',
          }}
          onClick={() => handleExperienceChange('positive')}
        >
          <AgFlex alignItems="center">
            <RadioButton
              selected={experience === 'positive'}
              onClick={() => handleExperienceChange('positive')}
            />
            <AgText
              sx={{
                variant: 'texts.sm-semibold',
                color: experience === 'positive' ? 'green.800' : 'new-gray.700',
              }}
            >
              Positive
            </AgText>
            <ThumbsUp
              sx={{
                ml: '8px',
                color: experience === 'positive' ? 'green.800' : 'new-gray.700',
              }}
            />
          </AgFlex>
        </AgBox>
        <AgBox
          sx={{
            cursor: 'pointer',
            transition: 'background 0.2s, border 0.2s',
            borderRadius: '12px',
            padding: '16px',
            background: experience === 'negative' ? '#ECFDF3' : '#FFFFFF',
            border:
              experience === 'negative'
                ? '1px solid #12B76A'
                : '1px solid #EAECF0',
          }}
          onClick={() => handleExperienceChange('negative')}
        >
          <AgFlex alignItems="center">
            <RadioButton
              selected={experience === 'negative'}
              onClick={() => handleExperienceChange('negative')}
            />
            <AgText
              sx={{
                variant: 'texts.sm-semibold',
                color: experience === 'negative' ? 'green.800' : 'new-gray.700',
              }}
            >
              Negative
            </AgText>
            <ThumbsDown
              sx={{
                ml: '8px',
                color: experience === 'negative' ? 'green.800' : 'new-gray.700',
              }}
            />
          </AgFlex>
        </AgBox>
      </AgFlex>
      <AgText
        sx={{
          variant: 'texts.sm-semibold',
          color: 'new-gray.700',
          mb: '6px',
        }}
      >
        Feedback reason
      </AgText>
      <AgFlex mb="16px">
        <DropdownButton
          ref={dropdownRef}
          buttonLabel={reason || 'Select one'}
          showHeader={false}
          showFooter={false}
          handleClear={() => setReason('')}
          buttonDisabled={!experience}
          cssStyles={{
            popUpStyles: {
              padding: 0,
              width: '100%',
            },
          }}
          styles={{
            Root: {
              width: '100%',
            },
            ButtonText: {
              fontSize: 'md',
            },
            Button: {
              width: '100%',
            },
          }}
        >
          <AgFlex flexDirection="column">
            {feedbackSelections[experience]?.map((option, index) => (
              <AgBox
                key={index}
                onClick={() => {
                  setReason(option);
                  if (dropdownRef?.current) {
                    dropdownRef.current.closeDropdown();
                  }
                }}
                sx={{
                  cursor: 'pointer',
                  padding: '8px 12px',
                  '&:hover': {
                    backgroundColor: '#F9FAFB',
                  },
                }}
              >
                <AgText
                  sx={{
                    color: 'new-gray.900',
                    variant: 'texts.md-regular',
                  }}
                >
                  {option}
                </AgText>
              </AgBox>
            ))}
          </AgFlex>
        </DropdownButton>
      </AgFlex>
      <AgText
        sx={{
          variant: 'texts.sm-semibold',
          color: 'new-gray.700',
          mb: '6px',
        }}
      >
        Feedback details
      </AgText>
      <AgTextarea
        value={details}
        onChange={getFeedbackDetails}
        placeholder="Additional details you would like us to know?"
        sx={{
          padding: '8px',
          borderRadius: '4px',
          border: '1px solid #ccc',
          height: '188px',
          resize: 'none',
          overflowY: 'auto',
          mb: '24px',
          width: '100%',
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '24px',
        }}
      />
      <AgFlex justifyContent="space-between">
        <AgButton
          onClick={handleOnSendFeedback}
          sx={{
            width: '48%',
            height: '44px',
            backgroundColor: '#12B76A',
            color: '#FFFFFF',
            border: '1px solid #12B76A',
            borderRadius: '8px',
            variant: 'texts.md-semibold',
          }}
        >
          Send Feedback
        </AgButton>
        <AgButton
          variant="outline"
          onClick={onClose}
          sx={{
            width: '48%',
            height: '44px',
            backgroundColor: '#FFFFFF',
            color: '#344054',
            border: '1px solid #D0D5DD',
            borderRadius: '8px',
            variant: 'texts.md-semibold',
          }}
        >
          Cancel
        </AgButton>
      </AgFlex>
    </AgBox>
  )
}

FeedbackModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSendFeedback: PropTypes.func,
}

export default FeedbackModal
