import React from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash/uniqueId'
import {AgCollapsible} from '@aghealth/ag-components'
import {Checkbox} from '.'

const defaultStyles = {
  Collapsible: {
    Root: {
      width: '100%',
      marginLeft: '0px',
    },
  },
  Checkbox: {
    Root: {
      ml: '20px',
    },
  },
}

const CheckboxDirectory = ({
  list,
  onChange,
  styles,
  marginLeft,
  overrideVisible,
}) => {
  return list.map(parent => {
    if (!parent.items || parent.items.length === 0) {
      // Render a regular Checkbox if the parent has no children
      return (
        <Checkbox
          key={uniqueId(parent.id)}
          id={parent.id}
          name={parent.name}
          selected={parent.selected}
          type={parent.type}
          metadata={parent.metadata}
          onChange={onChange}
          styles={{
            ...styles?.Checkbox,
            Root: {
              ...styles?.Checkbox?.Root,
              marginLeft: `${marginLeft}px`,
            },
            Input: {
              'data-testid': parent.type,
            },
            Label: {
              'data-testid': parent.type,
            },
          }}
        />
      )
    }

    // Render AgCollapsible if the parent has children
    return (
      <AgCollapsible
        icon="angle"
        disableOnClick={parent.disableOnClick}
        key={uniqueId(parent.id)}
        forceVisibility
        visible={overrideVisible ? parent.visible : !parent.selected}
        label={
          <Checkbox
            id={parent.id}
            name={parent.name}
            selected={parent.selected}
            onChange={onChange}
            type={parent.type}
            items={parent.items}
            metadata={parent.metadata}
            styles={{
              Input: {
                'data-testid': parent.type,
              },
              Label: {
                'data-testid': parent.type,
              },
            }}
          />
        }
        styles={{
          ...styles?.Collapsible,
          Button: {
            ...styles?.Collapsible?.Button,
            marginLeft: `${marginLeft}px`,
          },
        }}
      >
        {parent.items.map(child =>
          child.items && child.items.length ? (
            <CheckboxDirectory
              key={uniqueId(`${parent.id}-${child.id}`)}
              list={[child]}
              onChange={onChange}
              marginLeft={marginLeft + 20}
              overrideVisible={overrideVisible}
            />
          ) : (
            <Checkbox
              key={uniqueId(child.id)}
              id={child.id}
              name={child.displayName || child.name}
              selected={child.selected}
              type={child.type}
              metadata={child.metadata}
              onChange={onChange}
              styles={{
                ...styles?.Checkbox,
                Root: {
                  ...styles?.Checkbox?.Root,
                  marginLeft: `${marginLeft + 20}px`,
                },
                Input: {
                  'data-testid': child.type,
                },
                Label: {
                  'data-testid': child.type,
                },
              }}
            />
          ),
        )}
      </AgCollapsible>
    )
  })
}

CheckboxDirectory.propTypes = {
  list: PropTypes.array,
  onChange: PropTypes.func,
  marginLeft: PropTypes.number,
  styles: PropTypes.object,
  overrideVisible: PropTypes.bool,
}

CheckboxDirectory.defaultProps = {
  list: [],
  onChange: () => {},
  marginLeft: 0,
  styles: defaultStyles,
}

export default CheckboxDirectory
