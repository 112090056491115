/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, {
  Component,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import {animated} from 'react-spring'
import {AgBox} from '@aghealth/ag-components'
import {noop} from './utils'
import {prefix} from './settings'
import ContentEditable from './ContentEditable'
import SendButton from './SendButton'
import PerfectScrollbar from './Scroll'

const defaultSendButtonStyles = {
  bg: 'green.500',
  width: '44px',
  height: '44px',
  ml: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

const AnimatedBox = animated(AgBox)

// Because container depends on fancyScroll
// it must be wrapped in additional container
function editorContainer() {
  class Container extends Component {
    render() {
      const {
        props: {fancyScroll, children, forwardedRef, ...rest},
      } = this

      return (
        <>
          {fancyScroll === true && (
            <PerfectScrollbar
              ref={elRef => (forwardedRef.current = elRef)}
              {...rest}
              options={{suppressScrollX: true}}
            >
              {children}
            </PerfectScrollbar>
          )}
          {fancyScroll === false && (
            <div ref={forwardedRef} {...rest}>
              {children}
            </div>
          )}
        </>
      )
    }
  }

  return React.forwardRef((props, ref) => {
    return <Container forwardedRef={ref} {...props} />
  })
}

const EditorContainer = editorContainer()

const useControllableState = (value, initialValue) => {
  const initial = typeof value !== 'undefined' ? value : initialValue
  const [stateValue, setStateValue] = useState(initial)
  const effectiveValue = typeof value !== 'undefined' ? value : stateValue

  return [
    effectiveValue,
    newValue => {
      setStateValue(newValue)
    },
  ]
}

function MessageInputInner(
  {
    value,
    onSend,
    onChange,
    autoFocus,
    placeholder,
    fancyScroll,
    className,
    disabled,
    sendDisabled,
    sendOnReturnDisabled,
    attachDisabled,
    sendButton,
    showSendButtonIcon,
    sendButtonText,
    sendButtonStyles: _sendButtonStyles,
    attachButton,
    onAttachClick,
    height,
    ...rest
  },
  ref,
) {
  const scrollRef = useRef()
  const msgRef = useRef()
  // const [activateAfterChange, setActivateAfterChange] = useState(false)
  const [stateValue, setStateValue] = useControllableState(value, '')
  const [stateSendDisabled, setStateSendDisabled] = useControllableState(
    sendDisabled,
    true,
  )

  // Public API
  const focus = () => {
    if (typeof msgRef.current !== 'undefined') {
      msgRef.current.focus()
    }
  }

  const clearContent = () => {
    if (typeof msgRef.current !== 'undefined') {
      msgRef.current.innerHTML = ''
    }
  }

  // Return object with public Api
  useImperativeHandle(ref, () => ({
    focus,
    clearContent,
  }))

  // Set focus
  useEffect(() => {
    if (autoFocus === true) {
      focus()
    }
  }, [autoFocus])

  // Update scroll
  useEffect(() => {
    if (typeof scrollRef.current.updateScroll === 'function') {
      scrollRef.current.updateScroll()
    }
  })

  const getContent = () => {
    // Direct reference to contenteditable div
    const contentEditableRef = msgRef.current.msgRef.current
    return [
      contentEditableRef.textContent,
      contentEditableRef.innerText,
      contentEditableRef.cloneNode(true).childNodes,
    ]
  }

  const send = () => {
    if (stateValue.length > 0) {
      // Clear input only when it's uncontrolled mode
      if (value === undefined) {
        setStateValue('')
      }

      if (msgRef.current) {
        msgRef.current.clearContent()
      }

      // Disable send button only when it's uncontrolled mode
      if (typeof sendDisabled === 'undefined') {
        setStateSendDisabled(true)
      }

      const content = getContent()

      onSend(stateValue, content[0], content[1], content[2])
    } else {
      onSend('')
    }
    // setActivateAfterChange(true)
  }

  const handleKeyPress = evt => {
    if (
      evt.key === 'Enter' &&
      evt.shiftKey === false &&
      sendOnReturnDisabled === false
    ) {
      evt.preventDefault()
      send()
    }
    // setActivateAfterChange(true)
  }

  const handleChange = (innerHTML, textContent, innerText) => {
    setStateValue(textContent)
    if (typeof sendDisabled === 'undefined') {
      setStateSendDisabled(textContent.length === 0)
    }

    if (typeof scrollRef.current.updateScroll === 'function') {
      scrollRef.current.updateScroll()
    }

    const content = getContent()

    onChange(innerHTML, textContent, innerText, content[2])
  }

  const cName = `${prefix}-message-input`,
    ph = typeof placeholder === 'string' ? placeholder : ''

  const sendButtonStyles = React.useMemo(
    () => (_sendButtonStyles ? _sendButtonStyles : defaultSendButtonStyles),
    [_sendButtonStyles],
  )

  return (
    <AnimatedBox {...rest}>
      <AgBox
        sx={{
          border: '1px solid',
          borderColor: 'new-gray.300',
          borderRadius: '8px',
          boxShadow: 'xs',
          padding: '10px 14px',
          width: '100%',
          height,
        }}
      >
        <EditorContainer
          fancyScroll={fancyScroll}
          ref={scrollRef}
          className={`${cName}__content-editor-container`}
        >
          <ContentEditable
            ref={msgRef}
            className={`${cName}__content-editor`}
            disabled={disabled}
            placeholder={ph}
            onKeyPress={handleKeyPress}
            onChange={handleChange}
          />
        </EditorContainer>
      </AgBox>
      {sendButton === true && (
        <SendButton
          showIcon={showSendButtonIcon}
          sx={sendButtonStyles}
          onClick={send}
          disabled={disabled === true || stateSendDisabled === true}
        >
          {sendButtonText}
        </SendButton>
      )}
    </AnimatedBox>
  )
}

const MessageInput = forwardRef(MessageInputInner)
MessageInput.displayName = 'MessageInput'

MessageInput.propTypes = {
  /** Value. */
  value: PropTypes.string,

  /** Placeholder. */
  placeholder: PropTypes.string,

  /** A input can show it is currently unable to be interacted with. */
  disabled: PropTypes.bool,

  /** Prevent that the input message is sent on a return press */
  sendOnReturnDisabled: PropTypes.bool,

  /** Send button can be disabled.<br>
   * It's state is tracked by component, but it can be forced */
  sendDisabled: PropTypes.bool,

  /**
   * Fancy scroll
   * This property is set in constructor, and is not changing when component update.
   */
  fancyScroll: PropTypes.bool,

  /** Set focus after mount. */
  autoFocus: PropTypes.bool,

  /**
   * onChange handler<br>
   * @param {String} innerHtml
   * @param {String} textContent
   * @param {String} innerText
   * @param {NodeList} nodes
   */
  onChange: PropTypes.func,

  /**
   * onSend handler<br>
   * @param {String} innerHtml
   * @param {String} textContent
   * @param {String} innerText
   * @param {NodeList} nodes
   */
  onSend: PropTypes.func,

  /** Additional classes. */
  className: PropTypes.string,

  /** Show send button */
  sendButton: PropTypes.bool,
  showSendButtonIcon: PropTypes.bool,
  sendButtonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.func,
  ]),
  sendButtonStyles: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

MessageInputInner.propTypes = MessageInput.propTypes

MessageInput.defaultProps = {
  value: undefined,
  placeholder: '',
  disabled: false,
  sendOnReturnDisabled: false,
  fancyScroll: true,
  activateAfterChange: false,
  autoFocus: false,
  sendButton: true,
  showSendButtonIcon: true,
  attachButton: true,
  attachDisabled: false,
  onAttachClick: noop,
  onChange: noop,
  onSend: noop,
}

MessageInputInner.defaultProps = MessageInput.defaultProps

export {MessageInput}

export default MessageInput
