/* eslint-disable react/prop-types */
import React from 'react'
import styled from 'styled-components'
import {
  space,
  layout,
  color,
  typography,
  position,
  border,
  shadow,
} from 'styled-system'
import {FiInfo} from 'react-icons/fi'

const TooltipContainer = styled.div`
  ${space}
  ${layout}
  ${color}
  ${typography}
  ${position}
  ${border}
  ${shadow}
  position: relative;
  display: inline-block;
  cursor: pointer;
  vertical-align: middle;
  margin-left: ${({offsetX}) => offsetX || '5px'};
`

const TooltipText = styled.div`
  ${space}
  ${color}
  ${typography}
  ${position}
  ${border}
  ${shadow}
  visibility: hidden;
  width: ${({width}) => width || '300px'}; // Adjust as needed
  background-color: #f4f4f4; // Nice gray background
  color: #041424;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #cccccc; // Slightly darker gray border
  padding: 8px 12px;
  position: absolute;
  z-index: 1;
  bottom: 125%; // Position the tooltip above the icon
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  word-wrap: break-word;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 15px; // Increase the size of the triangle
    border-style: solid;
    border-color: #f4f4f4 transparent transparent transparent; // Same background color
  }

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 16px 16px 0 16px; // Increase the size of the border
    border-style: solid;
    border-color: #cccccc transparent transparent transparent; // Slightly darker gray border
    z-index: -1; // Place behind the ::after element
  }
`

const TooltipWrapper = styled.div`
  &:hover ${TooltipText}, &:hover .custom-tooltip {
    visibility: visible;
    opacity: 1;
  }
`

const CustomTooltipWrapper = styled.div`
  visibility: hidden;
  position: absolute;
  z-index: 1;
  bottom: 125%; // Position the tooltip above the icon
  left: 0%;
  opacity: 0;
  transition: opacity 0.3s;
`

const InfoIcon = styled(FiInfo)`
  width: 24px;
  height: 24px;
  color: #363636;
`

const InfoTooltip = ({
  text,
  Content,
  offsetX = '5px',
  width = '300px',
  ...props
}) => (
  <TooltipContainer offsetX={offsetX} {...props}>
    <TooltipWrapper>
      <InfoIcon />
      {Content ? (
        <CustomTooltipWrapper className="custom-tooltip" width={width}>
          <Content {...props} />
        </CustomTooltipWrapper>
      ) : (
        <TooltipText width={width}>{text}</TooltipText>
      )}
    </TooltipWrapper>
  </TooltipContainer>
)

export default InfoTooltip
