import {produce} from 'immer'
import {
  SET_CONFIG_DEPARTMENTS,
  SET_CONFIG_ERROR,
  SET_CONFIG_FEATURE_FLAGS,
  SET_CONFIG_LOADING,
  SET_CONFIG_USER,
  SET_CONFIG_SKIP_NPI_PROMPT,
  SET_CONFIG_SKIP_DEPT_PROMPT,
} from '../types'

export const createInitialState = () => {
  const skip_npi_prompt = !!JSON.parse(localStorage.getItem('skip_npi_prompt'))
  const skip_dept_prompt = !!JSON.parse(
    localStorage.getItem('skip_dept_prompt'),
  )
  return {
    departments: null,
    feature_flags: null,
    user: null,
    error: null,
    loading: true,
    skip_npi_prompt,
    skip_dept_prompt,
  }
}

export const initialState = createInitialState()

const reducer = (state = initialState, action) => {
  if (action.type === SET_CONFIG_DEPARTMENTS)
    return {...state, departments: action.payload}
  if (action.type === SET_CONFIG_ERROR) return {...state, error: action.payload}
  if (action.type === SET_CONFIG_FEATURE_FLAGS)
    return {...state, feature_flags: action.payload}
  if (action.type === SET_CONFIG_LOADING)
    return {...state, loading: action.payload}
  if (action.type === SET_CONFIG_SKIP_NPI_PROMPT) {
    if (action.meta?.onlyLocalStorage) {
      localStorage.setItem('skip_npi_prompt', JSON.stringify(action.payload))
      return state
    }
    localStorage.setItem('skip_npi_prompt', JSON.stringify(action.payload))
    return {...state, skip_npi_prompt: action.payload}
  }
  if (action.type === SET_CONFIG_SKIP_DEPT_PROMPT) {
    if (action.meta?.onlyLocalStorage) {
      localStorage.setItem('skip_dept_prompt', JSON.stringify(action.payload))
      return state
    }
    localStorage.setItem('skip_dept_prompt', JSON.stringify(action.payload))
    return {...state, skip_dept_prompt: action.payload}
  }
  if (action.type === SET_CONFIG_USER) {
    return produce(state, draftState => {
      draftState.user = action.payload
    })
  }

  return state
}

export default reducer
