import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isPlainObject from 'lodash/isPlainObject'
import {AgBox} from '@aghealth/ag-components'
import {Link} from 'react-router'
import parse from 'html-react-parser'
import {Light as SyntaxHighlighter} from 'react-syntax-highlighter'
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs'
import {prefix} from './settings'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

// function isHtml(str) {
//   return /<\/?[a-z][\s\S]*>/i.test(str)
// }

const remarkPlugins = [remarkGfm]

export const MessageHtmlContent = ({html, className, sx}) => {
  const cName = `${prefix}-message__html-content`
  const jsx = React.useMemo(() => {
    const options = {
      replace: ({attribs, children, data}) => {
        if (!attribs) {
          const markdownText =
            data || children?.map(child => child.data).join('') || ''

          return (
            <ReactMarkdown
              className="markdown-content"
              remarkPlugins={remarkPlugins}
            >
              {markdownText}
            </ReactMarkdown>
          )
        }

        if (attribs.href) {
          // replace <a> with <Link>
          const href = attribs.href
          const linkChildren = children.map(child =>
            child.type === 'text' ? child.data : child,
          )
          if (attribs.class && attribs.class.includes('sql')) {
            // Handle SQL code blocks
            const sqlText = children[0].data
            return (
              <SyntaxHighlighter language="sql" style={docco}>
                {sqlText}
              </SyntaxHighlighter>
            )
          } else {
            const to = new URL(href).pathname + new URL(href).search
            return (
              <Link to={to} style={{color: '#007BFF'}}>
                {linkChildren}
              </Link>
            )
          }
        }
      },
    }

    const _html = html?.replace(
      /```sql\n([\s\S]*?)\n```/g,
      (match, p1) => `<code class="sql">${p1}</code>`,
    )

    const parsed = parse(_html, options)

    return Array.isArray(parsed)
      ? parsed?.filter(p => isPlainObject(p))
      : parsed
  }, [html])

  return (
    <AgBox className={classNames(cName, className)} sx={sx}>
      {jsx}
    </AgBox>
  )
}

MessageHtmlContent.displayName = 'Message.HtmlContent'

MessageHtmlContent.propTypes = {
  /**
   * Html string will be rendered in component using dangerouslySetInnerHTML
   */
  html: PropTypes.string,

  /** Additional classes. */
  className: PropTypes.string,

  sx: PropTypes.object,
}

MessageHtmlContent.defaultProps = {
  sx: {},
}

export default MessageHtmlContent
