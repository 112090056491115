import React from 'react'
import PropTypes from 'prop-types'
import {
  AgImage,
  AgText,
  AgButton,
  AgBox,
  AgRow,
  AgColumn,
} from '@aghealth/ag-components'
import checkmark from '../assets/checkmark.png'

const IconStyles = {
  size: 45,
  bg: (_, props) => (props.selected ? '#3A5C3A' : '#B8E5F5'),
}

const getSelected = selections =>
  selections
    .reduce(
      (acc, selection) => [
        ...acc,
        ...selection.values.filter(v => v.selected).map(v => v.id),
      ],
      [],
    )
    .join()

const Card = ({name, icon, selected, onSelect, ...rest}) => (
  <AgColumn
    bg="#FFFFFF"
    height={150}
    width={175}
    boxShadow="metric-card"
    borderRadius="base"
    cursor="pointer"
    onClick={() =>
      onSelect({
        name,
        icon,
        selected,
        ...rest,
      })
    }
    data-testid="card-selection"
  >
    <AgRow
      width="100%"
      height={100}
      bg={selected ? '#3A5C3A' : '#B8E5F5'}
      alignItems="center"
      justifyContent="center"
      borderRadius="4px 4px 0px 0px"
    >
      <AgImage
        src={selected ? checkmark : icon}
        selected={selected}
        sx={IconStyles}
      />
    </AgRow>
    <AgRow width="100%" height={50} justifyContent="center" alignItems="center">
      <AgText
        p={2}
        fontSize="14px"
        color="font-primary"
        lineHeight="16px"
        textAlign="center"
      >
        {name}
      </AgText>
    </AgRow>
  </AgColumn>
)

Card.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.string,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

const Count = ({count, limit}) => (
  <AgRow justifyContent="space-between" alignItems="center">
    <AgButton
      width={57}
      height={57}
      bg="#B7DD85"
      fontSize="24px"
      lineHeight="28px"
      fontWeight="bold"
      color="#111111"
      borderRadius="8px"
      cursor="default"
      data-testid="selected-count"
    >
      {count}
    </AgButton>
    <AgText ml={2} color="#727891" fontSize="14px" lineHeight="20px">
      {`of ${limit} selected`}
    </AgText>
  </AgRow>
)

Count.propTypes = {
  count: PropTypes.number,
  limit: PropTypes.number,
}

const CardSelections = ({
  items,
  onSave,
  onCancel,
  type = 'metrics',
  limit = 5,
}) => {
  const [selections, setSelections] = React.useState([])
  const [count, setCount] = React.useState(0)
  React.useEffect(() => {
    const count = items.reduce((acc, item) => {
      const selected = item.values.filter(v => v.selected).length
      return (acc += selected)
    }, 0)
    setSelections(items)
    setCount(count)
  }, [items])
  const handleOnSelect = React.useCallback(
    selection => {
      if (count < limit || (selection.selected && count === limit)) {
        setSelections(prev =>
          prev.map(p => {
            const values = p.values.map(v => {
              if (v.id === selection.id) {
                const selected = !v.selected
                return {...v, selected}
              }
              return v
            })
            return {...p, values}
          }),
        )
        setCount(prev => (selection.selected ? prev - 1 : prev + 1))
      }
    },
    [count, limit],
  )
  return (
    <AgColumn width="100%" height={500} bg="#F8F8F8" borderRadius="8px">
      <AgRow
        height={100}
        alignItems="center"
        justifyContent="space-between"
        px={3}
        borderBottom="1px solid #CCCCCC"
      >
        <AgColumn>
          <AgText
            color="#1B263F"
            fontSize="28px"
            lineHeight="33px"
            fontWeight="bold"
          >
            {`What ${type} are most relevant for you?`}
          </AgText>
          <AgText color="#727891" fontSize="14px" lineHeight="20px">
            {`Select up to ${limit} ${type} below.`}
          </AgText>
        </AgColumn>
        <Count count={count} limit={limit} />
      </AgRow>
      <AgColumn
        sx={{
          height: 325,
          variant: 'scrollbars.y-hide',
        }}
      >
        {selections.map((selection, index) => (
          <AgColumn
            key={selection.id}
            px={3}
            mt={3}
            mb={index === selections.length - 1 ? 3 : 0}
          >
            <AgText fontSize="20px" lineHeight="23px" color="#1B263F">
              {selection.name}
            </AgText>
            <AgRow py={1} flexWrap="wrap">
              {selection.values.map(s => (
                <AgBox mr={3} my={2} key={s.id}>
                  <Card onSelect={handleOnSelect} {...s} />
                </AgBox>
              ))}
            </AgRow>
          </AgColumn>
        ))}
      </AgColumn>
      <AgRow height={75} alignItems="center" borderTop="1px solid #CCCCCC">
        <AgButton
          bg="#129459"
          borderRadius="sm"
          p={3}
          ml={3}
          color="white"
          fontSize="14px"
          lineHeight="16px"
          borderColor="#129459"
          border="base"
          onClick={() => onSave(getSelected(selections))}
        >
          Save Selections
        </AgButton>
        <AgButton
          bg="white"
          border="base"
          borderRadius="sm"
          color="#111111"
          borderColor="#129459"
          p={3}
          fontSize="14px"
          lineHeight="16px"
          ml={2}
          onClick={onCancel}
        >
          Cancel
        </AgButton>
      </AgRow>
    </AgColumn>
  )
}

CardSelections.propTypes = {
  items: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  type: PropTypes.string,
  limit: PropTypes.number,
}

export default CardSelections
