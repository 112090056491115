import React from 'react'
import {useLocation} from 'react-router'
import {useCallback, useEffect, useMemo, useState} from 'react'

import {useSelector} from 'react-redux'

import {MdOutlinePushPin} from 'react-icons/md'

import {
  AgAlert,
  AgButton,
  AgFixed,
  AgFlex,
  AgSpinner,
  AgText,
} from '@aghealth/ag-components'

import {createFavorite} from '../../../api'

import colors from '../../../theme/colors'

import {selectChartFilterParams} from '../utils/selectors'
import {isNullList, selectDefaultDepartment} from '../../../utils'

const ICON_SIZE = 24
const TOAST_TIMEOUT_IN_MILLISECONDS = 7000
const DEFAULT_CHART_ID = 1
const CHART_IDS_WITH_NO_CHART_VIEW = [49]

const styles = {
  Root: {
    marginLeft: '20px',
    borderRadius: 'sm',
    border: 'base',
    borderColor: 'btn-border-green',
    height: '44px',
    px: '16px',
    bg: 'base.white',
    cursor: 'pointer',
  },
  DisabledButton: {
    opacity: 0.4,
    cursor: 'default',
  },
  Text: {
    fontSize: 'sm',
    fontWeight: 'md',
    color: 'btn-bg-green',
    marginLeft: '4px',
  },
  ToastContainer: {bottom: '16px', right: '16px', zIndex: 'xxxlg'},
  ErrorAlert: {
    Root: {backgroundColor: 'error.500'},
    Button: {color: 'base.white'},
  },
}

const AddToDashboardButton = () => {
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()

  const filterParams = useSelector(state =>
    selectChartFilterParams(state, location.search),
  )
  const defaultDepartment = useSelector(selectDefaultDepartment)
  const [toast, setToast] = useState({
    message: null,
    isError: false,
  })

  const clearToastMessage = useCallback(() => {
    setToast({
      message: null,
      isError: false,
    })
  }, [])

  const selectedChartId = useSelector(state => state?.charts?.config?.id)
  const chartsData = useSelector(state => state?.charts?.data)
  const isChartLoading = useSelector(
    state => state.charts.loading_data || state.charts.loading,
  )

  const handleClick = useCallback(async () => {
    if (isLoading) {
      return
    }
    setIsLoading(true)
    const searchParams = new URLSearchParams(location.search)
    let parsedFilterParams = new URLSearchParams(filterParams)

    // Check for missing or empty department_ids and department_id
    if (
      !parsedFilterParams.has('department_ids') ||
      !parsedFilterParams.get('department_ids')
    ) {
      if (defaultDepartment?.id) {
        parsedFilterParams.set('department_ids', defaultDepartment?.id)
      }
    }

    // Check for missing or empty procedure_group_ids and procedure_group_id
    if (
      !parsedFilterParams.has('procedure_group_ids') ||
      !parsedFilterParams.get('procedure_group_ids')
    ) {
      parsedFilterParams.set('procedure_group_ids', 0)
    }

    // Check for missing or empty time_period_id
    if (
      !parsedFilterParams.has('time_period_id') ||
      !parsedFilterParams.get('time_period_id')
    ) {
      parsedFilterParams.set('time_period_id', 1)
    }

    parsedFilterParams = parsedFilterParams.toString()

    try {
      await createFavorite({
        filter_params: parsedFilterParams,
        chart_id: +searchParams.get('chart_id') || DEFAULT_CHART_ID,
      })

      setToast({
        message: 'Chart added to dashboard successfully.',
        isError: false,
      })
    } catch (error) {
      if (error?.response?.msg) {
        setToast({
          message: error.response.msg,
          isError: true,
        })
      }
    } finally {
      setIsLoading(false)
    }
  }, [filterParams, location.search, isLoading, defaultDepartment?.id])

  useEffect(() => {
    let timeoutId

    if (toast.message) {
      timeoutId = setTimeout(() => {
        clearToastMessage()
      }, TOAST_TIMEOUT_IN_MILLISECONDS)
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId)
      }
    }
  }, [toast.message, clearToastMessage])

  const isAddToDashboardDisabled = useMemo(() => {
    return isLoading || isNullList(chartsData) || isChartLoading
  }, [chartsData, isLoading, isChartLoading])

  const alertStyles = useMemo(() => {
    return toast.isError ? styles.ErrorAlert : {}
  }, [toast.isError])

  const rootStyles = useMemo(() => {
    return isAddToDashboardDisabled
      ? {...styles.Root, ...styles.DisabledButton}
      : {...styles.Root}
  }, [isAddToDashboardDisabled])

  if (CHART_IDS_WITH_NO_CHART_VIEW.includes(selectedChartId)) {
    return <></>
  }

  return (
    <>
      <AgButton
        sx={{...rootStyles}}
        onClick={handleClick}
        disabled={isAddToDashboardDisabled}
      >
        <AgFlex alignItems="center">
          <AgFlex>
            {isLoading ? (
              <AgSpinner color={colors['btn-bg-green']} size={ICON_SIZE} />
            ) : (
              <MdOutlinePushPin
                size={ICON_SIZE}
                color={colors['btn-bg-green']}
              />
            )}
          </AgFlex>
          <AgText sx={styles.Text}>Add to Homepage</AgText>
        </AgFlex>
      </AgButton>

      {toast.message && (
        <AgFixed sx={styles.ToastContainer}>
          <AgAlert
            onClose={clearToastMessage}
            styles={alertStyles}
            message={toast.message}
          />
        </AgFixed>
      )}
    </>
  )
}

export default AddToDashboardButton
