import {getHeaders, baseURL} from './utils'

const endpoint = `${baseURL}/api/project_guidance/project_plan`

const baselineEndpoint = `${baseURL}/api/project_guidance/baseline_project_plan`

const stepEndpoint = `${baseURL}/api/project_guidance/project_plan_step`

const massagePlan = result => {
  let completedIndex = result?.data?.steps?.findIndex(s => s.is_completed)

  const steps =
    completedIndex === -1
      ? result?.data?.steps?.map((s, i) => {
          return {
            ...s,
            name: s?.name || '',
            active: i === 0,
          }
        })
      : result?.data?.steps?.map((s, i) => {
          return {
            ...s,
            name: s?.name || '',
            active: i <= completedIndex + 1,
          }
        })
  const data = {
    ...result?.data,
    steps,
  }
  return Promise.resolve({data})
}

export const getBaselineProjectPlan = async (type, options = {}) => {
  const url = `${baselineEndpoint}/${type}`
  return fetch(url, {
    ...options,
    method: 'GET',
    headers: await getHeaders(),
  }).then(async r => {
    if (!/2[0-9][0-9]/g.test(r.status)) {
      if (r.status === 404)
        return Promise.resolve({
          data: {
            steps: [],
          },
        })
      return Promise.reject({
        status: r.status,
        message: 'Failed to fetch baseline project plan',
      })
    }
    const result = await r.json()
    const steps = result?.data?.steps?.map(s => {
      return {
        ...s,
        is_completed: false,
      }
    })
    return Promise.resolve({
      data: {
        ...result?.data,
        steps,
      },
    })
  })
}

export const createProjectPlan = async options => {
  const {body} = options
  return fetch(endpoint, {
    ...options,
    body: JSON.stringify(body),
    method: 'POST',
    headers: await getHeaders(),
  }).then(async r => {
    if (!/2[0-9][0-9]/g.test(r.status)) {
      const result = await r.json()
      return Promise.reject(result)
    }
    const result = await r.json()
    return massagePlan(result)
  })
}

export const getProjectPlan = async (id, options = {}) => {
  const url = `${endpoint}/${id}`
  return fetch(url, {
    ...options,
    method: 'GET',
    headers: await getHeaders(),
  }).then(async r => {
    if (!/2[0-9][0-9]/g.test(r.status)) {
      const result = await r.json()
      return Promise.reject(result)
    }
    const result = await r.json()
    return massagePlan(result)
  })
}

export const updateProjectPlan = async (id, options) => {
  const {body} = options
  const url = `${endpoint}/${id}`
  return fetch(url, {
    ...options,
    body: JSON.stringify(body),
    method: 'PUT',
    headers: await getHeaders(),
  }).then(async r => {
    if (!/2[0-9][0-9]/g.test(r.status)) {
      const result = await r.json()
      return Promise.reject(result)
    }
    const result = await r.json()
    return massagePlan(result)
  })
}

export const deleteProjectPlan = async (id, options = {}) => {
  const url = `${endpoint}/${id}`
  return fetch(url, {
    ...options,
    method: 'DELETE',
    headers: await getHeaders(),
  })
}

export const updateProjectPlanStep = async (id, options) => {
  const {body} = options
  const url = `${stepEndpoint}/${id}`
  return fetch(url, {
    ...options,
    body: JSON.stringify(body),
    method: 'POST',
    headers: await getHeaders(),
  })
}

export const createProjectPlanComment = async (step_id, body) => {
  const url = `${baseURL}/api/project_guidance/project_plan_step/${step_id}/comment`
  return fetch(url, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: await getHeaders(),
  }).then(async r => {
    if (!r.ok) {
      const result = await r.json()
      return Promise.reject(result)
    }
    const result = await r.json()
    return result
  })
}
