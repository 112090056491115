import React from 'react'
import {useHistory} from '../hooks'

import PropTypes from 'prop-types'

import {AgRow, AgImage} from '@aghealth/ag-components'

import notificationV2 from '../assets/icon-notification-nav-v2.png'
import notification from '../assets/icon-notification.svg'

// const filters = {
//   blue: 'invert(47%) sepia(72%) saturate(1718%) hue-rotate(190deg) brightness(102%) contrast(101%)',
//   black:
//     'invert(0%) sepia(0%) saturate(0%) hue-rotate(174deg) brightness(97%) contrast(105%)',
// }

const Notification = ({onClick, active, isNavV2}) => (
  <AgImage
    active={active}
    src={isNavV2 ? notificationV2 : notification}
    onClick={onClick}
    sx={{
      cursor: 'pointer',
      width: isNavV2 ? 20 : 26,
      height: isNavV2 ? 20 : 26,
      mr: isNavV2 ? 16 : 30,
      // filter: active ? filters.blue : filters.black,
      transition: 'filter 0.3ms ease-in',
      fontWeight: 'bold',
    }}
  />
)

Notification.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  isNavV2: PropTypes.bool,
}

const AlertIcon = ({active, isNavV2}) => {
  const history = useHistory()
  const handleRedirect = () => {
    if (isNavV2) {
      history.push('/alerts-v2')
    } else {
      history.push('/alerts')
    }
  }

  return (
    <AgRow height="100%" alignItems="center">
      <Notification
        active={active}
        onClick={handleRedirect}
        isNavV2={isNavV2}
      />
    </AgRow>
  )
}

AlertIcon.propTypes = {
  active: PropTypes.bool,
  isNavV2: PropTypes.bool,
}

export default AlertIcon
