import isArray from 'lodash/isArray'
import {
  SET_PAC_FILTERS,
  SET_PAC_DATA,
  SET_PAC_LOADING,
  SET_PAC_NOT_LOADING,
  SET_PAC_ERROR,
  SET_PAC_FILTERS_LOADING,
  SET_PAC_ROWS_LIMIT,
  SET_PAC_CURRENT_PAGE,
  SET_PAC_TOTAL_ROWS,
  SET_PAC_SORT_BY,
  SET_PAC_SORT_DIRECTION,
  SET_PAC_TOTAL_PAGES,
  TOGGLE_PAC_HIDE_FACILITIES,
  PAC_HIDE_FACILITIES,
} from '../types'

import {_fetchPACFilters, fetchPACData as _fetchPACData} from '../../api'

import {
  selectOnlyOneById,
  selectOneById,
  unselectOneById,
  unselectAll,
} from '../../utils'

const fetchPACData = options =>
  _fetchPACData(options).then(response => {
    if (isArray(response.data.results)) {
      return Promise.resolve(response.data)
    }
    if (response.status === 403) {
      return Promise.reject({status: response.status})
    }
    return Promise.reject({status: 'no-data'})
  })

const _toParams = ({
  bundle,
  location,
  distance,
  care_level,
  preferred,
  rating,
  limit,
  current_page,
  sort_by,
  sort_dir,
  hide_facilities,
}) => ({
  bundle: bundle.filter(b => b.selected).map(b => b.value),
  location,
  distance: distance.filter(d => d.selected).map(d => d.value),
  care_level: care_level.filter(c => c.selected).map(c => c.value),
  preferred: preferred.filter(p => p.selected).map(p => p.value),
  rating: rating.filter(r => r.selected).map(r => r.value),
  limit: [limit],
  page: [current_page],
  'sort-by': [sort_by],
  'sort-dir': [sort_dir],
  'hide-low-volume': [hide_facilities],
})

const toParams = (getState, overrides = {}) => {
  const {...filters} = getState().pac.filters
  const {limit, current_page, sort_by, sort_dir, hide_facilities} =
    getState().pac
  const state = {
    ...filters,
    ...overrides,
    limit,
    current_page,
    sort_by,
    sort_dir,
    hide_facilities,
  }
  return _toParams(state)
}

const saveResponse = (response, dispatch) => {
  dispatch(setData(response.results))
  dispatch(setRowsLimit(response.limit))
  dispatch(setCurrentPage(response.current_page))
  dispatch(setTotalRows(response.total_rows))
  dispatch(setSortBy(response.sort_by))
  dispatch(setSortDirection(response.sort_dir))
  dispatch(setTotalPages(response.total_pages))
}

const setHideFacilities = () => ({type: TOGGLE_PAC_HIDE_FACILITIES})

const toggleHideFacilities = () => (dispatch, getState) => {
  dispatch(setHideFacilities())
  dispatch(setLoading())
  const params = toParams(getState)
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const hideFacilities = payload => ({type: PAC_HIDE_FACILITIES, payload})

const toggleBundleType = selection => (dispatch, getState) => {
  const {bundle: current_bundle} = getState().pac.filters
  const bundle = current_bundle.map(
    selection.selected
      ? unselectOneById(selection)
      : selectOnlyOneById(selection),
  )
  dispatch(setFilters({bundle}))
  const params = toParams(getState)
  dispatch(setLoading())
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const toggleDistance = selection => (dispatch, getState) => {
  const {distance: current_distance} = getState().pac.filters
  const distance = current_distance.map(
    selection.selected
      ? unselectOneById(selection)
      : selectOnlyOneById(selection),
  )
  dispatch(setFilters({distance}))
  dispatch(setLoading())
  const params = toParams(getState)
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const updateCareLevel = updatedList => (dispatch, getState) => {
  dispatch(setFilters({care_level: updatedList}))
  dispatch(setLoading())
  const params = toParams(getState)
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const toggleCareLevel = selection => (dispatch, getState) => {
  const {care_level: current_care_level} = getState().pac.filters
  const care_level = current_care_level.map(
    selection.selected ? unselectOneById(selection) : selectOneById(selection),
  )
  dispatch(setFilters({care_level}))
  dispatch(setLoading())
  const params = toParams(getState)
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const togglePreferred = selection => (dispatch, getState) => {
  const {preferred: current_preferred} = getState().pac.filters
  const preferred = current_preferred.map(
    selection.selected
      ? unselectOneById(selection)
      : selectOnlyOneById(selection),
  )
  dispatch(setFilters({preferred}))
  dispatch(setLoading())
  const params = toParams(getState)
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const clearAllFilters = () => (dispatch, getState) => {
  const {
    bundle: current_bundle,
    distance: current_distance,
    care_level: current_care_level,
    preferred: current_preferred,
    rating: current_rating,
  } = getState().pac.filters
  const bundle = current_bundle.map(unselectAll)
  const distance = current_distance.map(unselectAll)
  const care_level = current_care_level.map(unselectAll)
  const preferred = current_preferred.map(unselectAll)
  const rating = current_rating.map(unselectAll)
  dispatch(hideFacilities(false))
  dispatch(
    setFilters({
      bundle,
      distance,
      care_level,
      preferred,
      rating,
    }),
  )
  const params = toParams(getState)
  dispatch(setLoading())
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}
const toggleRating = selection => (dispatch, getState) => {
  const {rating: current_rating} = getState().pac.filters
  const rating = current_rating.map(
    selection.selected
      ? unselectOneById(selection)
      : selectOnlyOneById(selection),
  )
  dispatch(setFilters({rating}))
  dispatch(setLoading())
  const params = toParams(getState)
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const setLocation = payload => (dispatch, getState) => {
  dispatch(setFilters({location: payload}))
  const params = toParams(getState)
  dispatch(setLoading())
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const toggleCurrentPage = selection => (dispatch, getState) => {
  dispatch(setCurrentPage(selection))
  const params = toParams(getState)
  dispatch(setLoading())
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const toggleRowsLimit = selection => (dispatch, getState) => {
  dispatch(setRowsLimit(selection))
  dispatch(setLoading())
  const params = toParams(getState)
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const toggleTableSort = column => (dispatch, getState) => {
  const {sort_by: sortBy, sort_dir: sortDir} = getState().pac
  const directions = ['asc', 'desc']
  let _sortDir = 'asc'
  if (sortBy === column) {
    const direction = directions.filter(direction => direction !== sortDir)[0]
    _sortDir = direction
  }
  dispatch(setSortBy(column))
  dispatch(setSortDirection(_sortDir))
  dispatch(setLoading())
  const params = toParams(getState)
  fetchPACData({params}, {getState, dispatch})
    .then(data => {
      saveResponse(data, dispatch)
      dispatch(setNotLoading())
    })
    .catch(error => {
      dispatch(setError(error))
    })
}

const setFilters = payload => ({type: SET_PAC_FILTERS, payload})
const setFiltersLoading = payload => ({type: SET_PAC_FILTERS_LOADING, payload})
const setData = payload => ({type: SET_PAC_DATA, payload})
const setLoading = () => ({type: SET_PAC_LOADING})
const setNotLoading = () => ({type: SET_PAC_NOT_LOADING})
const setError = payload => ({type: SET_PAC_ERROR, payload})

const setRowsLimit = payload => ({type: SET_PAC_ROWS_LIMIT, payload})
const setCurrentPage = payload => ({type: SET_PAC_CURRENT_PAGE, payload})
const setTotalRows = payload => ({type: SET_PAC_TOTAL_ROWS, payload})
const setSortBy = payload => ({type: SET_PAC_SORT_BY, payload})
const setSortDirection = payload => ({type: SET_PAC_SORT_DIRECTION, payload})
const setTotalPages = payload => ({type: SET_PAC_TOTAL_PAGES, payload})

const fetchFilters =
  ({signal}) =>
  (dispatch, getState) => {
    const [selected] = getState().config.user.organizations.filter(
      o => o.selected,
    )
    const {zip} = selected

    _fetchPACFilters({signal})
      .then(filters => {
        const [
          bundle,
          // eslint-disable-next-line
          location,
          distance,
          care_level,
          preferred,
          rating,
        ] = filters
        const payload = {
          bundle: bundle.values,
          location: [zip],
          distance: distance.values,
          care_level: care_level.values,
          preferred: preferred.values,
          rating: rating.values,
        }
        dispatch(setFilters(payload))
        dispatch(setFiltersLoading(false))
        dispatch(fetch(signal))
      })
      .catch(error => {
        dispatch(setError(error))
        dispatch(setFiltersLoading(false))
      })
  }

const fetch =
  ({signal, params = {}}) =>
  (dispatch, getState) => {
    params = toParams(getState)
    fetchPACData({signal, params})
      .then(response => {
        saveResponse(response, dispatch)
        dispatch(setNotLoading())
      })
      .catch(error => {
        dispatch(setError(error))
        dispatch(setNotLoading())
      })
  }

const actions = {
  setFilters,
  setData,
  setLoading,
  setNotLoading,
  setError,
  fetch,
  fetchFilters,
  toggleBundleType,
  toggleDistance,
  toggleCareLevel,
  updateCareLevel,
  togglePreferred,
  toggleRating,
  setLocation,
  clearAllFilters,
  toggleCurrentPage,
  toggleRowsLimit,
  toggleTableSort,
  toggleHideFacilities,
}

export default actions
