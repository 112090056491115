import React from 'react'
import {Navigate, useParams} from 'react-router'

const withValidateReport = Page => {
  const Container = () => {
    const {report} = useParams()
    return ['procedures', 'physicians', 'cases'].includes(report) ? (
      <Page />
    ) : (
      <Navigate to="/data-import-report/procedures" replace={true} />
    )
  }
  return Container
}

export default withValidateReport
