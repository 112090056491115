import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {BrowserRouter} from 'react-router'
import {Toaster} from 'react-hot-toast'
import {AgBox, AgColumn} from '@aghealth/ag-components'
import {config} from './store/actions'
import {withNPIPrompt, withSpecialtiesPrompt} from './hocs'
import {useDidMount, useHistory} from './hooks'
import {selectHasChatbot} from './utils'
import {Footer, ErrorMessage} from './components'
import {Chatbot} from './containers'
import LazyRoutes from './routes/LazyRoutes'
import NavbarV2 from './containers/NavbarV2'
import FeedbackButton from './components/Feedback/FeedbackButton'

const Fail = React.memo(props => (
  <AgColumn width="100%" height="100%" alignItems="center">
    <ErrorMessage
      {...props}
      height="calc(100vh - 125px)"
      width="90%"
      bg="box-bg-blue"
      borderRadius="base"
      mt="20px"
    />
  </AgColumn>
))

const Success = React.memo(() => {
  const history = useHistory()

  React.useEffect(() => {
    history.location.state = undefined
  }, [history])

  return <LazyRoutes />
})

const Main = () => {
  const error = useSelector(state => state?.config?.error)
  const hasChatbot = useSelector(selectHasChatbot)

  return (
    <BrowserRouter>
      <NavbarV2 />
      {hasChatbot && <Chatbot />}
      <AgBox
        mt={70}
        mb={50}
        bg="blue"
        minHeight="calc(100vh - 120px)"
        width="100%"
        data-testid="main"
        sx={{
          variant: 'scrollbars.y-hide',
        }}
      >
        {!error ? <Success /> : <Fail {...error} />}
        <Footer />
      </AgBox>
      <FeedbackButton />
      <Toaster />
    </BrowserRouter>
  )
}

const MainWithPrompts = withNPIPrompt(withSpecialtiesPrompt(Main))

const App = () => {
  const dispatch = useDispatch()
  const loading = useSelector(state => state.config.loading)

  useDidMount(signal => {
    dispatch(config.fetch({signal}))
  })

  if (loading) return null

  return <MainWithPrompts />
}

export default App
