import isNumber from 'lodash/isNumber'
import {isGroupBySurgeon} from './utils'

const adjustSortColumn = col => {
  if (Array.isArray(col)) {
    const [first, ...rest] = col
    return [first, ...rest.map(n => n + 1)]
  }
  if (isNumber(col)) return col + 1
  return col
}

const withFacility = create => config => {
  const {sortColumn, isAscSort} = create(config)
  if (isGroupBySurgeon(config)) {
    return {isAscSort, sortColumn: adjustSortColumn(sortColumn)}
  }
  return {sortColumn, isAscSort}
}

const createSort = config => {
  const sortColumn = [49].includes(config.id)
    ? config.groupByManufacturer
      ? [1, 2, 4]
      : [1, 2, 5]
    : [48].includes(config.id)
      ? 2
      : null
  const isAscSort = [49].includes(config.id)
    ? [true, true, false]
    : ![48].includes(config.id)
  return {sortColumn, isAscSort}
}

export default withFacility(createSort)
