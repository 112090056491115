import {getHeaders, baseURL} from './utils'

const endpoint = `${baseURL}/api/scorecards`

export const fetchScorecard = async (id = null, options = {}) => {
  const {method, body, onSuccess = () => {}, onFail = () => {}} = options
  const url = id ? `${endpoint}/${id}` : endpoint
  return fetch(url, {
    ...options,
    method,
    headers: await getHeaders(),
    body: body ? JSON.stringify(body) : undefined,
  }).then(r =>
    method === 'GET'
      ? r.json()
      : /2[0-9][0-9]/g.test(r.status)
        ? onSuccess(r)
        : onFail(r),
  )
}

export const fetchScorecards = async options => {
  const {signal} = options
  const url = endpoint
  return fetch(url, {
    ...options,
    method: 'GET',
    signal,
    headers: await getHeaders(),
  })
    .then(r => r.json())
    .then(response => Promise.resolve(response.data))
}
