 
import {lazy} from 'react'

export const lazyRetry = (componentImport, name) =>
  lazy(() => {
    const key = `retry-lazy-${name}-refreshed`
    return new Promise((resolve, reject) => {
      // check if the window has already been refreshed
      const hasRefreshed = JSON.parse(
        window.sessionStorage.getItem(key) || false,
      )
      // try to import the component
      componentImport()
        .then(component => {
          window.sessionStorage.setItem(key, false) // success so reset the refresh
          resolve(component)
        })
        .catch(error => {
          if (!hasRefreshed) {
            // not been refreshed yet
            window.sessionStorage.setItem(key, true) // we are now going to refresh
            return window.location.reload() // refresh the page
          }
          reject(error) // Default error behaviour as already tried refresh
        })
    })
  })
