import React from 'react'
import PropTypes from 'prop-types'
import uniqueId from 'lodash/uniqueId'
import groupBy from 'lodash/groupBy'
import isNumber from 'lodash/isNumber'
import kebabCase from 'lodash/kebabCase'
import {format, parse} from '../format'
import {
  ManufacturerDetails,
  SupplyDetails,
  SparkBarColumn,
  CaseLevelView,
} from '../../components'
import {hasGroupBySurgeonName} from '../config/utils'

export const toDollarString = value => format(value, '$')

const MaxSupplyBar = ({supply_max, supply_cost}) => (
  <SparkBarColumn
    max={supply_max}
    value={supply_cost}
    formatter={toDollarString}
  />
)

MaxSupplyBar.propTypes = {
  supply_max: PropTypes.number,
  supply_cost: PropTypes.number,
}

const MaxQuantityBar = ({quantity_max, quantity_used}) => (
  <SparkBarColumn max={quantity_max} value={quantity_used} />
)

MaxQuantityBar.propTypes = {
  quantity_max: PropTypes.number,
  quantity_used: PropTypes.number,
}

const jsx = {
  config_49_manufacturer_name: props => <ManufacturerDetails {...props} />,
  config_49_supply_cost: MaxSupplyBar,
  config_49_quantity_used: MaxQuantityBar,
  config_48_hospital_case_number: props => <SupplyDetails {...props} />,
  config_case: props => <CaseLevelView {...props} />,
}

const formatValue = (type, value, showWeekly) => {
  // Change to render 0 instead of -
  // NOTE: Might affect some other pages, need to check and test in long term
  if (!type && isNumber(value)) return value
  if (!type) return !value ? '-' : value
  const result = format(value, type, showWeekly)
  return !result ? '-' : result
}

export const formatColumn = (column = {}, data, showWeekly) => {
  const el =
    column.title === 'Case Information'
      ? jsx.config_case
      : jsx[`config_${column.id}_${column.key}`]
  if (el) {
    return React.createElement(el, {
      showSupplyDetailsView: column.showSupplyDetailsView,
      groupByManufacturer: column.groupByManufacturer,
      ...data,
    })
  }
  return formatValue(
    column.format,
    parse(data[column.key], column.parse, showWeekly),
    showWeekly,
  )
}

const getAlignment = (key, index, length) => {
  if (index === 0) return 'left'
  if (length < 3) return 'left'
  if (['organization_full_name'].includes(key)) return 'left'
  return 'right'
}

const createColumn = column => ({
  heading: column.title,
  align: ![49].includes(column.id) ? column.align : undefined,
  accessor: d => d[column.key],
  format: (_, d) => formatColumn(column, d, column.showWeekly),
  testid: kebabCase(column.title),
  _key: column.key,
})

export const formatColumns = ({
  columns,
  id,
  showSupplyDetailsView,
  groupByManufacturer,
  showWeekly,
}) =>
  columns.map((column, index) => {
    const align = getAlignment(column?.key, index, columns?.length)
    return createColumn({
      ...column,
      align,
      id,
      showSupplyDetailsView,
      groupByManufacturer,
      showWeekly,
    })
  })

export const filterBySupplyCategory = cache => category =>
  !!cache[category.category_id]

export const getRow = config => {
  const row = config.table.columns[0]
  const key = config.table.row_key_accessor
    ? config.table.row_key_accessor
    : row.key
  return {...row, key}
}

const computeRowName = (d, key) => {
  if (key === 'surgeon_name') {
    return hasGroupBySurgeonName(d)
      ? `${d[key]} (${d.organization_short_name})`
      : d[key]
  }
  return d[key]
}

export const createRowKeyAccessor = config => {
  const row = getRow(config)
  return config.id === 49
    ? () => uniqueId('row_')
    : d => format(parse(computeRowName(d, row.key), row.parse), row.format)
}

const getPPTotalCost = a => {
  if (isNumber(a?.supply_cost)) return a.supply_cost * a.quantity_used
  return 0
}

export const groupByManufacturer = data => {
  const groups = groupBy(data, d => d.manufacturer_name)
  const _data = Object.values(groups).reduce((acc, values) => {
    const uniques = Object.values(groupBy(values, v => v.product_name)).reduce(
      (acc, _products) => {
        const products = _products.map(p => ({
          ...p,
          price_point_total_cost: getPPTotalCost(p),
        }))

        const unique = products.reduce(
          (a, p) => {
            a = {
              ...p,
              case_count: a.case_count + p.case_count,
              quantity_used: a.quantity_used + p.quantity_used,
              price_point_total_cost:
                a.price_point_total_cost + p.price_point_total_cost,
            }
            return a
          },
          {case_count: 0, quantity_used: 0, price_point_total_cost: 0},
        )
        const supply_cost = unique.price_point_total_cost / unique.quantity_used
        return [...acc, {...unique, supply_cost}]
      },
      [],
    )
    return [...acc, ...uniques]
  }, [])
  return _data
}

export const isGroupBySurgeon = config =>
  config.isSystemView &&
  config.groupBySurgeon &&
  config.data.some(d => d.organization_name)

export const isGroupByOrganization = config =>
  config.isSystemView &&
  config.groupByOrganization &&
  config.data.some(d => d.organization_name)

const removeOrganizationName = d => {
  const name = d.surgeon_name.split('(').slice(0, 1).join().trimRight()
  return name
}

export const withRemoveOrganizationNames = config => ({
  ...config,
  data: config.data.map(d => ({
    ...d,
    surgeon_name: hasGroupBySurgeonName(d)
      ? removeOrganizationName(d)
      : d.surgeon_name,
  })),
})
